import React, { useEffect, useState } from 'react';
import { Box, SxProps, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { Exam } from './type';
import { formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';

interface CountDownToStartProps {
  exam: Exam | null; // The exam object containing info about the exam
  timediff: number; // Time difference between local and server time (in seconds)
  showUserForm?: boolean; // Whether the user form is showing
  // showUpdateProfile?: boolean; // Whether the update profile modal is showing
  user: any; // The user object, could be typed more specifically based on your project
  onStart: (start: boolean) => void; // Callback to indicate when to start
  sx?: SxProps;
}

export const formatTime = (seconds: number) => {
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = seconds % 60;

  if (hours > 1) {
    return (
      formatDistanceToNow(new Date(Date.now() + seconds * 1000), {
        locale: vi,
      }) + ' nữa'
    );
  } else {
    return `${hours > 0 ? hours + ':' : ''}${
      minutes < 10 && hours > 0 ? '0' : ''
    }${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  }
};

const CountDownToStart: React.FC<CountDownToStartProps> = ({
  exam,
  timediff,
  showUserForm = false,
  // showUpdateProfile = false,
  user,
  onStart,
  sx,
}) => {
  const [countDownToStart, setCountDownToStart] = useState(0);
  const theme = useTheme(); // Access theme

  useEffect(() => {
    if (!exam) return; // Exit early if no exam       // && user
    if (!showUserForm && exam) {
      if (exam.isExercise) {
        // if (showUpdateProfile) return; // If the user needs to update their profile, exit early
        // If it's an exercise, start immediately
        onStart(true);
      } else {
        if (!exam.executedAt) return; // If no start time, exit early

        // Calculate time until the exam starts
        const startAt = new Date(exam.executedAt);
        const now = new Date(Date.now() + timediff * 1000);

        const diff = startAt.getTime() - now.getTime();
        setCountDownToStart(Math.floor(diff / 1000));

        if (diff <= 0) {
          let countDown = exam.duration * 60 + Math.floor(diff / 1000);
          if (countDown > 0) {
            // If the exam should already be started
          } else {
            setCountDownToStart(0);
          }
          onStart(true);
        } else {
          onStart(false);

          // Set an interval to count down until the exam starts
          const timer = setInterval(() => {
            setCountDownToStart((prevTime) => {
              if (prevTime > 0) {
                return prevTime - 1;
              } else {
                clearInterval(timer);
                onStart(true);
                return 0;
              }
            });
          }, 1000);

          // Cleanup the interval when component unmounts
          return () => clearInterval(timer);
        }
      }
    }
  }, [exam, timediff, showUserForm, user, onStart]); //, showUpdateProfile

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        flexDirection: 'column',
        ...sx,
      }}
    >
      <Typography
        variant="h4"
        textAlign="center"
        sx={{
          background: `linear-gradient(to right, ${theme.palette.prize.first}, #ff7e5f)`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent', // Transparent to show the gradient
        }}
      >
        Đề sẽ được mở sau
      </Typography>
      <Typography
        variant="h2"
        textAlign="center"
        sx={{
          background: `linear-gradient(to right, ${theme.palette.prize.first}, #ff7e5f)`,
          WebkitBackgroundClip: 'text',
          WebkitTextFillColor: 'transparent', // Transparent to show the gradient
        }}
      >
        {formatTime(countDownToStart)}
      </Typography>
    </Box>
  );
};

export default CountDownToStart;
