import { Box, Container, Divider, Typography, useTheme } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import useResponsive from '../../hooks/useResponsive';
import useAuth from '../../hooks/useAuth';
import { Result } from '../Exams/type';
import { fetchResultByStudentId, fetchResults } from '../../api';
import {
  CalculateScore,
  formatCompleteTime,
  getRank,
} from '../Exams/extractQuestions';
import { translateSubject } from '../Exams/docUtils';
import LoadingDisplay from './Loading';
import ErrorDisplay from './Error';

const CheckResultsPage: FC = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const [results, setResults] = useState<Result[] | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const StyledTypography = ({ children, ...props }: any) => (
    <Typography
      textAlign="center"
      fontWeight={600}
      {...props}
      sx={{
        background: `linear-gradient(to right, ${theme.palette.prize.first}, #ff7e5f)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        ...props.sx,
      }}
    >
      {children}
    </Typography>
  );

  const columnProps = [
    {
      name: 'Ngày',
      width: '10%',
      minWidth: 100,
      maxWidth: '10%',
      render: (result: Result) =>
        new Date(result.createdAt || '').toLocaleDateString(),
    },
    {
      name: 'Tên',
      width: '20%',
      minWidth: 200,
      maxWidth: '20%',
      render: (result: Result) => result.student?.name || '',
    },
    {
      name: 'Môn học',
      width: '10%',
      minWidth: 100,
      maxWidth: '10%',
      render: (result: Result) => translateSubject(result.exam?.subject || ''),
    },
    {
      name: 'Tên đề',
      width: '20%',
      minWidth: 100,
      maxWidth: '20%',
      render: (result: Result) => result.exam?.title || '',
    },
    {
      name: 'Thời gian',
      width: '10%',
      minWidth: 100,
      maxWidth: '10%',
      render: (result: Result) => formatCompleteTime(result.completeTime),
    },
    {
      name: 'Điểm',
      width: '10%',
      minWidth: 100,
      maxWidth: '10%',
      render: (result: Result) => result.score,
    },
    {
      name: 'Thứ hạng',
      width: '10%',
      minWidth: 100,
      maxWidth: '10%',
      render: (result: Result) => result.rank,
    },
  ];

  useEffect(() => {
    const fetchResultsfunc = async () => {
      if (!user?._id) return;
      try {
        const res = await fetchResults();
        const fetchedResults: Result[] = res;
        if (fetchedResults) {
          const resultsWithScores = fetchedResults.map((result) => ({
            ...result,
            score: result.exam
              ? CalculateScore(result.answers, result.exam.questions)
              : 0,
            rank: getRank(result),
          }));
          setResults(resultsWithScores);
          setLoading(false);
        }
      } catch (error) {
        console.error(error);
        setError('Đã xảy ra lỗi. Vui lòng thử lại sau.');
      }
    };
    fetchResultsfunc();
  }, [user?._id]);

  if (loading) {
    return <LoadingDisplay loadingMessage="Đang tải kết quả..." />;
  }

  if (error) {
    return <ErrorDisplay errorMessage={error} />;
  }

  return (
    <Container>
      <Typography variant="h3" textAlign="left">
        Kết quả kiểm tra
      </Typography>
      {results?.length === 0 ? (
        <Typography variant="h6" textAlign="center">
          Không có dữ liệu kết quả
        </Typography>
      ) : (
        <>
          <StyledTypography
            variant="h3"
            sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}
          >
            {user?.name}
          </StyledTypography>
          <Box
            display="flex"
            alignItems="center"
            flexDirection="column"
            width="100%"
            maxWidth="100%"
            sx={{
              overflow: 'auto',
              '&::-webkit-scrollbar': {
                height: '8px',
              },
              maxHeight: '50vh',
            }}
          >
            <Box width="100%">
              {/* Header Columns */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
                px={1}
                py={1}
                mt={1}
              >
                {columnProps.map((col) => (
                  <StyledTypography
                    key={col.name}
                    textAlign="center"
                    fontWeight={600}
                    sx={{
                      maxWidth: col.maxWidth,
                      width: col.width,
                      minWidth: col.minWidth,
                    }}
                  >
                    {col.name}
                  </StyledTypography>
                ))}
              </Box>
              <Divider
                sx={{
                  backgroundColor: 'text.secondary',
                  opacity: 1,
                  width: '100%',
                  minWidth: 800,
                }}
              />
            </Box>

            {/* Rows */}
            {results?.map((result) => (
              <Box key={result.studentId} width="100%">
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                  width="100%"
                  p={1.5}
                  sx={{
                    borderBottom: `1px solid ${theme.palette.divider}`,
                  }}
                >
                  {columnProps.map((col) => (
                    <Typography
                      key={col.name}
                      textAlign="center"
                      fontWeight={500}
                      sx={{
                        maxWidth: col.maxWidth,
                        width: col.width,
                        minWidth: col.minWidth,
                        display: '-webkit-box', // Required for limiting lines of text
                        WebkitLineClamp: 1, // Limits to 2 lines; you can adjust this number
                        WebkitBoxOrient: 'vertical', // Required for multiline ellipsis
                        overflow: 'hidden', // Hides the overflowing text
                        textOverflow: 'ellipsis', // Adds '...' at the end of the truncated text
                      }}
                    >
                      {col.render(result)}
                    </Typography>
                  ))}
                </Box>
                <Divider
                  sx={{
                    backgroundColor: 'text.secondary',
                    opacity: 0.3,
                    minWidth: 800,
                  }}
                />
              </Box>
            ))}
          </Box>
        </>
      )}
    </Container>
  );
};

export default CheckResultsPage;
