import * as React from 'react';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import NavLinkStyled from './style';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import content from '../../constants/content';
import Logo from '../Logo';
import { Avatar, Divider, Stack, Typography, useTheme } from '@mui/material';
import paths from '../../constants/paths';
import useResponsive from '../../hooks/useResponsive';
import { fetchBuyCoursesByStudentId } from '../../api';
import { CourseProps } from '../../pages/Home/Course';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import BottomNavBar from './BottomNavBar';

import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
import { useNavigate } from 'react-router-dom';
import LeftNavBar from './LeftNavBar';
import { StudentInfo } from '../../model/Student';

interface Props {
  openNav: boolean;
  handleCloseNavMenu: () => void;
  handleOpenNavMenu: () => void;
  isDesktop: boolean | null;
  isMobile: boolean | null;
  drawerWidth: number;
  user: StudentInfo | null;
}

interface NavListItemProps {
  page: {
    text: string;
    path: string;
    icon: React.ElementType; // Icon component type
  };
}

const NavListItem: React.FC<NavListItemProps> = ({ page }) => {
  const location = useLocation();
  const isOnPath = location.pathname === page.path;
  return (
    <ListItem
      sx={{
        backgroundColor: isOnPath ? 'background.paper' : 'transparent',
        borderRadius: 1,
        px: 1,
        '&:hover': {
          backgroundColor: 'background.paper', // Hover effect
        },
      }}
    >
      <NavLinkStyled component={RouterLink} to={page.path}>
        <ListItemIcon
          sx={{
            minWidth: 'auto',
            mr: 2,
          }}
        >
          <page.icon style={{ fontSize: 22 }} sx={{ color: 'text.primary' }} />
        </ListItemIcon>
        <Typography variant="subtitle2">{page.text}</Typography>
      </NavLinkStyled>
    </ListItem>
  );
};

const NavListItem2: React.FC<CourseProps> = (course) => {
  const location = useLocation();
  const isOnPath = location.pathname === '/course/' + course.courseId;
  return (
    <ListItem
      sx={{
        py: 1,
        px: 1,
        backgroundColor: isOnPath ? 'background.paper' : 'transparent',
        borderRadius: 1,
        '&:hover': {
          backgroundColor: 'background.paper', // Hover effect
        },
      }}
    >
      <NavLinkStyled component={RouterLink} to={'course/' + course.courseId}>
        {/* <Avatar
          src={course.createdBy.avatar}
          sx={{ width: 22, height: 22, minWidth: 'auto', mr: 2 }}
        >
          <AccountCircleIcon />
        </Avatar> */}
        <img
          src={course.createdBy.avatar}
          alt="avatar"
          style={{
            height: 22,
            width: 22,
            borderRadius: '50%',
            minWidth: 'auto',
            marginRight: 12,
          }}
          referrerPolicy="no-referrer"
        />
        <Typography
          variant="subtitle2"
          sx={{
            display: '-webkit-box', // Required for limiting lines of text
            WebkitLineClamp: 1, // Limits to 2 lines; you can adjust this number
            WebkitBoxOrient: 'vertical', // Required for multiline ellipsis
            overflow: 'hidden', // Hides the overflowing text
            textOverflow: 'ellipsis', // Adds '...' at the end of the truncated text
            height: 'auto', // Ensures the height adapts to content
          }}
        >
          {course.title}
        </Typography>
      </NavLinkStyled>
    </ListItem>
  );
};

const NavDrawer: React.FC<Props> = ({
  openNav,
  handleCloseNavMenu,
  handleOpenNavMenu,
  drawerWidth,
  isDesktop,
  isMobile,
  user,
}) => {
  const theme = useTheme();
  const [contentYou, setContentYou] = React.useState<any[]>(
    content.NAV_LINK_YOU
  );

  React.useEffect(() => {
    if (user?.role === 'student') {
      //Remove the second item in the array, keep the first and the last
      setContentYou(
        content.NAV_LINK_YOU.slice(0, 1).concat(content.NAV_LINK_YOU.slice(2))
      );
    }
  }, [user, contentYou]);

  return (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <Drawer
        sx={{
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
            boxSizing: 'border-box',
          },
        }}
        variant={isDesktop ? 'persistent' : 'temporary'}
        anchor="left"
        open={openNav}
        onClose={handleCloseNavMenu}
      >
        <Toolbar
          disableGutters
          sx={{
            minHeight: '50px !important',
            backgroundColor: theme.palette.background.default,
            color: theme.palette.lighter.main,
            paddingX: {
              xs: 1,
            },
          }}
        >
          <IconButton
            size="large"
            aria-label="close navigation"
            aria-haspopup="true"
            onClick={handleCloseNavMenu}
            sx={{
              color: theme.palette.lighter.main,
              opacity: 0.6,
              '&:hover': {
                opacity: 1,
              },
            }}
          >
            <MenuIcon />
          </IconButton>
          <Stack direction="row" justifyContent="center" alignItems="center">
            <NavLinkStyled
              sx={{
                mr: 2,
                flexGrow: 1,
                color: 'inherit',
                fontSize: 23,
                fontWeight: 500,
                fontFamily: 'Freeman, sans-serif', // 'Oswald, sans-serif', //'Cormorant SC, serif', //'Oswald, sans-serif', //'_MontserratBold',
                mx: 0.5,
                opacity: 1,
              }}
              component={RouterLink}
              to={paths.HOME}
            >
              <Logo />
              {content.LOGO}
            </NavLinkStyled>
          </Stack>
        </Toolbar>
        <List
          sx={{
            height: '100%',
            backgroundColor: theme.palette.background.default,
            px: 2,
          }}
        >
          {content.NAV_LINK.map((page: any) => (
            <NavListItem key={page.text} page={page} />
          ))}

          {/* Divider between sections */}
          <Divider
            sx={{
              my: 1,
              backgroundColor: 'text.secondary',
              opacity: 0.3,
            }}
          />

          <Typography
            mt={1.5}
            mb={0.5}
            variant="body2"
            sx={{ color: theme.palette.lighter.main, opacity: 0.6 }}
          >
            You
          </Typography>

          {/* Render NAV_LINK_YOU items */}
          {contentYou.map((page: any) => (
            <NavListItem key={page.text} page={page} />
          ))}

          <Divider
            sx={{
              my: 1,
              backgroundColor: 'text.secondary',
              opacity: 0.3,
            }}
          />

          <Typography
            mt={1.5}
            mb={0.5}
            variant="body2"
            sx={{ color: theme.palette.lighter.main, opacity: 0.6 }}
          >
            Subcriptions
          </Typography>

          {user?.buyCourses?.map((course) => (
            <NavListItem2 key={course.courseId} {...course} />
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default NavDrawer;
