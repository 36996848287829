import React, {
  useState,
  useEffect,
  useImperativeHandle,
  forwardRef,
} from 'react';
import { Box, Typography } from '@mui/material';
import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';
import { useTheme } from '@mui/material/styles';
import { Exam } from './type';

// Define the props interface
interface CountDownProps {
  start: boolean; // Whether the countdown should start
  exam: Exam | null; // Exam information
  timediff: number;
  onOutTime?: () => void; // Callback function when time is up
  onTimeUp?: () => void; // Callback function when time is up
}

// Helper function to format time in minutes and seconds
const formatTimeMinutes = (seconds: number) => {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
};

// Forward ref to expose the timeLeft
const CountDown = forwardRef(
  ({ start, exam, timediff, onOutTime, onTimeUp }: CountDownProps, ref) => {
    const [timeLeft, setTimeLeft] = useState<number>(3600); // Initialize with the exam's duration
    const theme = useTheme(); // Access the theme

    // Use useImperativeHandle to expose timeLeft to the parent component
    useImperativeHandle(ref, () => ({
      getTimeLeft: () => timeLeft, // Expose this function for the parent to call
    }));

    // Start countdown timer when `start` is true
    useEffect(() => {
      if (!start || timeLeft === 0) return;

      const timer = setInterval(() => {
        setTimeLeft((prevTimeLeft) => {
          if (prevTimeLeft > 0) {
            return prevTimeLeft - 1;
          } else {
            clearInterval(timer);
            if (onTimeUp && prevTimeLeft === 0) onTimeUp();
            return 0;
          }
        });
      }, 1000);

      return () => clearInterval(timer);
    }, [start, onTimeUp]);

    useEffect(() => {
      if (!exam) return;

      if (exam.isExercise) {
        setTimeLeft(exam.duration * 60);
      }
    }, [exam]);

    // useEffect(() => {
    //   if (!exam) return;

    //   if (!exam.isExercise) {
    //     if (!exam.executedAt) return;

    //     const startAt = new Date(exam.executedAt);
    //     const now = new Date(Date.now() + timediff * 1000);

    //     const diff = startAt.getTime() - now.getTime();
    //     if (diff <= 0) {
    //       let countDown = exam.duration * 60 + Math.floor(diff / 1000);
    //       // setTimeLeft(countDown < 0 ? -1 : countDown);
    //       if (countDown < 0) {
    //         setTimeLeft(0);
    //         onOutTime && onOutTime();
    //       } else {
    //         setTimeLeft(countDown);
    //       }
    //     } else {
    //       setTimeLeft(exam.duration * 60);
    //     }
    //   }
    // }, [exam, timediff]);

    useEffect(() => {
      if (!exam) return;

      const updateTimer = () => {
        if (!exam.isExercise) {
          if (!exam.executedAt) return;

          const startAt = new Date(exam.executedAt);
          const now = new Date(Date.now() + timediff * 1000);

          const diff = startAt.getTime() - now.getTime();
          if (diff <= 0) {
            let countDown = exam.duration * 60 + Math.floor(diff / 1000);
            if (countDown < 0) {
              setTimeLeft(0);
              onOutTime && onOutTime();
            } else {
              setTimeLeft(countDown);
            }
          } else {
            setTimeLeft(exam.duration * 60);
          }
        }
      };

      // Execute the function immediately
      updateTimer();

      // Then set up the interval
      const interval = setInterval(updateTimer, 5000);

      return () => clearInterval(interval); // Clean up on component unmount
    }, [exam, timediff]);

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'flex-start',
          background: `linear-gradient(to right, ${theme.palette.prize.first}, #ff7e5f)`,
          borderRadius: 2,
          padding: 1,
          px: 2,
          color: 'primary.contrastText',
          width: 94,
        }}
      >
        <AccessAlarmIcon fontSize="small" />
        <Typography variant="subtitle2" ml={0.5} fontWeight={600}>
          {formatTimeMinutes(timeLeft)}
        </Typography>
      </Box>
    );
  }
);

export default CountDown;
