import React, { useState } from 'react';
import { Typography, Dialog, Box, SxProps } from '@mui/material';
import PropTypes from 'prop-types';

interface TruncatedTextWithDialogProps {
  content: string;
  sx?: SxProps;
  dialogContent: React.ReactNode;
  variant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'subtitle1'
    | 'subtitle2'
    | 'body1'
    | 'body2'
    | 'caption'
    | 'button'
    | 'overline'
    | undefined;
}

const TruncatedTextWithDialog: React.FC<TruncatedTextWithDialogProps> = ({
  content,
  sx,
  dialogContent,
  variant = 'h6',
}) => {
  const [showDialog, setShowDialog] = useState(false);

  const handleOpenDialog = () => {
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Typography
        variant={variant}
        onClick={handleOpenDialog}
        sx={{
          display: '-webkit-box',
          WebkitLineClamp: 1,
          WebkitBoxOrient: 'vertical',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          cursor: 'pointer',
          ...sx, // Allows custom styling through props
        }}
      >
        {content}
      </Typography>
      <Dialog open={showDialog} onClose={handleCloseDialog} maxWidth="sm">
        <Box p={3}>{dialogContent}</Box>
      </Dialog>
    </>
  );
};

export default TruncatedTextWithDialog;
