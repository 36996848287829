import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Rating,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import { useDispatch } from 'react-redux';
import { appActions } from '../../redux/slices/appSlice';
import MainButton from './MainButton';
import {
  PersonAddAltOutlined,
  Person,
  Search,
  Delete,
} from '@mui/icons-material';
import {
  addBuyCourse,
  addRate,
  findEmails,
  getStudentByCourseId,
  removeBuyCourse,
  removRate as removeRate,
} from '../../api';
import {
  beCourseMember,
  rateCourse,
  unBeCourseMember,
  unRateCourse,
} from '../../redux/slices/courseItemSlice';
import { StudentInfo } from '../../model/Student';
import { authActions } from '../../redux/slices/authSlice';
import { CourseProps } from '../Home/Course';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';

type CourseActionsProps = {
  user: StudentInfo | null;
  courseId: string;
  course: CourseProps;
  contactLink?: string;
  price: number;
  isMember: boolean;
  setIsMember: React.Dispatch<React.SetStateAction<boolean>>;
  rate: number;
  setRate: React.Dispatch<React.SetStateAction<number>>;
  isTeacher?: boolean;
};

const CourseActions: React.FC<CourseActionsProps> = ({
  user,
  courseId,
  contactLink,
  price,
  isMember,
  setIsMember,
  rate,
  setRate,
  course,
  isTeacher,
}) => {
  const dispatch = useDispatch();
  const [ratingValue, setRatingValue] = useState<number | null>(5); // Rating value
  const [isDialogOpen, setIsDialogOpen] = useState(false); // State for dialog visibility
  const [isBuyLoading, setIsBuyLoading] = useState(false);
  const [isManageDialogOpen, setIsManageDialogOpen] = useState(false);
  const [students, setStudents] = useState<StudentInfo[]>([]); // List of students

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [users, setUsers] = useState<any[]>([]); // State to store searched users
  const [selectedUser, setSelectedUser] = useState<StudentInfo | null>(null);

  // Handle search input changes
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  // Fetch users from API based on the search term
  useEffect(() => {
    const fetchUsers = async () => {
      if (searchTerm.length > 2) {
        setLoading(true);
        try {
          const response = await findEmails(searchTerm); // Replace with your token logic
          setUsers(response.data.students); // Assuming the API returns an array of users
        } catch (error) {
          console.error('Error fetching users:', error);
        } finally {
          setLoading(false);
        }
      } else {
        setUsers([]);
      }
    };

    fetchUsers();
  }, [searchTerm]);

  const handleSelectUser = (user: any) => {
    setSelectedUser(user);
  };

  const handleSubmit = async () => {
    if (!selectedUser) return;
    setLoading(true);
    try {
      if (!selectedUser._id) return;
      // Call the API to add the selected user to the course
      await addBuyCourse(selectedUser._id, courseId); // Replace with your token logic
      // Add the student to the list
      setStudents((prev) => [...prev, selectedUser]);

      dispatch(
        appActions.showNotification({
          variant: 'success',
          message: 'Thêm học sinh thành công.',
        })
      );
    } catch (err) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Thêm học sinh thất bại.',
        })
      );
    } finally {
      setLoading(false);
    }
  };

  // Dummy data fetch functions
  const fetchStudents = async () => {
    try {
      const res = await getStudentByCourseId(courseId); // Replace with API call to get students by course ID
      console.log('res', res);
      setStudents(res);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  };

  const handleRemoveStudent = async (studentId: string) => {
    try {
      // Replace with API call to remove a student by ID
      console.log(`Removing student with ID: ${studentId}`);
      await removeBuyCourse(studentId, courseId);
      setStudents((prev) => prev.filter((s) => s._id !== studentId));
      dispatch(
        appActions.showNotification({
          message: 'Học sinh đã được xóa thành công',
        })
      );
    } catch (error) {
      console.error('Error removing student:', error);
    }
  };

  // Open the rating dialog
  const onRateClick = async () => {
    if (!user || !user._id) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Bạn cần đăng nhập để đánh giá khóa học',
        })
      );
      return;
    }

    if (rate !== -1) {
      await removeRate(user._id, courseId);
      dispatch(unRateCourse(rate));
      dispatch(
        appActions.showNotification({
          message: 'Đã xóa đánh giá của bạn',
        })
      );
      setRate(-1); // Set rated state to false
    } else {
      setIsDialogOpen(true); // Open the dialog when the button is clicked
    }
  };

  // Handle the submission of the rating
  const handleSubmitRating = async () => {
    if (!user || !user._id) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Bạn cần đăng nhập để mua khóa học',
        })
      );
      setIsBuyLoading(false);
      return;
    }
    if (ratingValue === null) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Vui lòng chọn đánh giá trước khi nộp',
        })
      );
      return;
    }
    await addRate(user._id, courseId, ratingValue);
    dispatch(rateCourse(ratingValue));
    // Submit the rating logic (replace this with your API logic)
    dispatch(
      appActions.showNotification({
        message: `Cảm ơn bạn đã đánh giá`,
      })
    );

    setRate(ratingValue); // Set rated state to true
    setIsDialogOpen(false); // Close the dialog
  };

  // Handle buying course logic
  const onBuyCourseClick = async () => {
    setIsBuyLoading(true); // Start loading for buy course button
    if (!user || !user._id) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Bạn cần đăng nhập để mua khóa học',
        })
      );
      setIsBuyLoading(false);
      return;
    }

    try {
      if (!isMember) {
        if (price > 0) {
          dispatch(
            appActions.showLinkNotification({
              message: 'Liên hệ với giảng viên để mua khóa học: ',
              link: contactLink || '',
            })
          );
          setIsBuyLoading(false);
          return;
        } else {
          await addBuyCourse(user._id, courseId);
          dispatch(beCourseMember());
          setIsMember(true);
          dispatch(
            appActions.showNotification({
              message: 'Đã theo dõi khóa học',
            })
          );
          dispatch(authActions.addNewCourseToUserBuyCourses(course));
        }
      } else {
        if (price > 0) {
          dispatch(
            appActions.showNotification({
              variant: 'error',
              message: 'Bạn đã mua khóa học này rồi',
            })
          );
          setIsBuyLoading(false);
        } else {
          await removeBuyCourse(user._id, courseId);
          dispatch(unBeCourseMember());
          dispatch(
            appActions.showNotification({
              message: 'Đã bỏ theo dõi khóa học',
            })
          );
          setIsMember(false);
          dispatch(authActions.removeCourseFromUserBuyCourses(course));
        }
      }
    } catch (err) {
      console.error('Error updating course membership:', err);
    } finally {
      setTimeout(() => setIsBuyLoading(false), 500); // Delay for at least 0.5 seconds
    }
  };

  const theme = useTheme();

  return (
    <Box>
      {isTeacher && (
        <Button
          fullWidth
          onClick={() => {
            setIsManageDialogOpen(true);
            fetchStudents(); // Load students when opening the dialog
          }}
          startIcon={<SettingsIcon />}
          variant="outlined"
          sx={{
            mb: 1,
          }}
        >
          Quản lý học sinh
        </Button>
      )}
      {/* Rating Button */}
      <Button
        fullWidth
        onClick={onRateClick}
        startIcon={rate > -1 ? <StarRoundedIcon /> : <StarBorderRoundedIcon />}
        variant="outlined"
        sx={{
          mb: 1,
        }}
      >
        {rate > -1 ? 'Đã đánh giá' : 'Đánh giá'}
      </Button>

      {/* Dialog for rating */}
      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
        <DialogTitle>Đánh giá khóa học</DialogTitle>
        <DialogContent sx={{ py: 0 }}>
          <Box textAlign="center">
            <Rating
              value={ratingValue}
              onChange={(event, newValue) => {
                setRatingValue(newValue);
              }}
              size="large"
              icon={<StarRoundedIcon fontSize="inherit" />}
              emptyIcon={<StarBorderRoundedIcon fontSize="inherit" />}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="secondary">
            Hủy
          </Button>
          <Button onClick={handleSubmitRating} variant="contained">
            Gửi
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={isManageDialogOpen}
        onClose={() => setIsManageDialogOpen(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Quản lý học sinh</DialogTitle>
        <DialogContent>
          <Box display="flex" flexDirection="column" mb={2}>
            <TextField
              fullWidth
              placeholder="Tìm kiếm email"
              value={searchTerm}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />

            {/* Users List */}
            <List sx={{ mt: 2, maxHeight: 300, overflowY: 'auto' }}>
              {loading ? (
                <CircularProgress />
              ) : users.length === 0 && searchTerm.length > 2 ? (
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{ textAlign: 'center' }}
                >
                  Không tìm thấy kết quả.
                </Typography>
              ) : (
                users.map((user) => (
                  <ListItem
                    key={user.email}
                    button
                    onClick={() => handleSelectUser(user)}
                    selected={selectedUser === user.email}
                  >
                    <ListItemAvatar>
                      {/* <Avatar src={user.avatar}>
                    <PersonOutlinedIcon />
                  </Avatar> */}
                      <img
                        src={user.avatar}
                        alt="avatar"
                        style={{ height: 40, width: 40, borderRadius: '50%' }}
                        referrerPolicy="no-referrer"
                      />
                    </ListItemAvatar>
                    <ListItemText primary={user.name} secondary={user.email} />
                  </ListItem>
                ))
              )}
            </List>

            {/* Submit button */}
            <Button
              variant="contained"
              fullWidth
              sx={{ mt: 3 }}
              onClick={handleSubmit}
              disabled={loading || !selectedUser} // Disable if loading or no user selected
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Thêm học sinh'
              )}
            </Button>
          </Box>
          <List>
            {students.map((student) => (
              <ListItem key={student._id}>
                <ListItemAvatar>
                  <img
                    src={student.avatar}
                    alt="avatar"
                    style={{ height: 40, width: 40, borderRadius: '50%' }}
                    referrerPolicy="no-referrer"
                  />
                </ListItemAvatar>
                <ListItemText
                  primary={student.name}
                  secondary={student.email}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    onClick={() =>
                      student._id && handleRemoveStudent(student._id)
                    }
                  >
                    <Delete />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setIsManageDialogOpen(false)}
            color="secondary"
          >
            Đóng
          </Button>
        </DialogActions>
      </Dialog>

      {/* Main Button for buying course */}
      <MainButton
        text={
          price === 0
            ? isMember
              ? 'Bỏ theo dõi khoá'
              : 'Theo dõi khoá'
            : isMember
            ? 'Đã mua khoá'
            : 'Mua khóa học'
        }
        isLoading={isBuyLoading}
        onClick={onBuyCourseClick}
        icon={isMember ? <Person /> : <PersonAddAltOutlined />}
        variant="first"
      />
    </Box>
  );
};

export default CourseActions;
