import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Typography,
  SxProps,
  Theme,
  alpha,
  useTheme,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import MCQQuestion from './MCQuestion';
import MCTFQuestion from './MCTFQuestion';
import SAQuestion from './SAQuestion';
import { Question } from './type';
import { RenderHTMLContent, RenderHTMLContent2 } from './extractQuestions';
import MainButton from '../Courses/MainButton';
import ImageBox from './ImageBox';
import useResponsive from '../../hooks/useResponsive';

// Props for the Question component
interface QuestionProps {
  question: Question;
  editable?: boolean;
  sx?: SxProps<Theme>;
  onAnswerChange?: (answer: string) => void;
  answer?: string;
  formatSize?: number;
  checkCorrect?: boolean;
  onSAQuestionCheck?: (check: boolean) => void;
  showCheckButton?: boolean;
}

const QuestionComponent: React.FC<QuestionProps> = ({
  question,
  editable,
  sx,
  onAnswerChange,
  answer,
  formatSize = 0.8,
  checkCorrect = false,
  onSAQuestionCheck,
  showCheckButton = false,
}) => {
  const theme = useTheme();
  const isCorrect = question.correctAnswer === answer;
  const isMobile = useResponsive('down', 'md');

  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth, naturalHeight } = e.currentTarget;
    setImageSize({
      width: naturalWidth * 0.5,
      height: naturalHeight * 0.5,
    });
  };

  useEffect(() => {
    try {
      if (!isMobile) {
        if (window.MathJax) {
          window.MathJax.typesetPromise().catch((err: unknown) =>
            console.error('MathJax Error: ', err)
          );
        }
      }
    } catch (err) {
      console.error('Error in MathJax: ', err);
    }
  }, [question, isMobile]);

  // Conditional rendering based on question type
  const renderQuestionType = () => {
    switch (question.type) {
      case 'mcq':
        return (
          <MCQQuestion
            question={question}
            editable={editable}
            onAnswerChange={onAnswerChange}
            answer={answer}
            formatSize={formatSize}
            checkCorrect={checkCorrect}
          />
        );
      case 'mctf':
        return (
          <MCTFQuestion
            question={question}
            editable={editable}
            onAnswerChange={onAnswerChange}
            answer={answer}
            formatSize={formatSize}
            checkCorrect={checkCorrect}
          />
        );
      case 'sa':
        return (
          <SAQuestion
            question={question}
            editable={editable}
            onAnswerChange={onAnswerChange}
            answer={answer}
            formatSize={formatSize}
            checkCorrect={checkCorrect}
          />
        );
      default:
        return <Typography variant="body1">Unknown question type</Typography>;
    }
  };

  return (
    <Box sx={{ px: 1, maxWidth: '100%', fontSize: `${formatSize}rem`, ...sx }}>
      <Typography variant="examText" gutterBottom>
        <strong>{question.section}</strong>
        {RenderHTMLContent(question.sectionContent || '')}
      </Typography>

      <Divider
        sx={{ my: 1, backgroundColor: 'text.secondary', opacity: 0.3 }}
      />

      {question.reuseContent && (
        <Box sx={{ position: 'relative' }}>
          {question.reuseContentImageUrl && (
            <ImageBox
              src={question.reuseContentImageUrl}
              alt={`Question ${question.index}`}
              formatSize={formatSize}
            />
          )}
          <Typography variant="examText" gutterBottom>
            {RenderHTMLContent(question.reuseContent)}
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          position: 'relative',
          minHeight:
            question.type === 'mctf' && question.questionImageUrl
              ? imageSize.height * formatSize + 20
              : 'auto',
        }}
      >
        {question.questionImageUrl && (
          // <ImageBox
          //   src={question.questionImageUrl}
          //   alt={`Question ${question.index}`}
          //   formatSize={formatSize}
          // />
          <Box
            component="img"
            src={question.questionImageUrl}
            alt={`Question ${question.index}`}
            onLoad={handleImageLoad}
            sx={{
              float: 'right',
              marginLeft: 2,
              marginBottom: 2,
              width: imageSize.width * formatSize + 'px',
              height: 'auto',
              maxWidth: isMobile ? '100%' : '75%',
            }}
          />
        )}
        <Typography variant="examText">
          <strong>Câu {question.index}:</strong>{' '}
          {RenderHTMLContent(question.question)}
          {/* <span
            dangerouslySetInnerHTML={{
              __html: question.question,
            }}
          /> */}
        </Typography>
      </Box>

      {renderQuestionType()}

      {onSAQuestionCheck && question.type === 'sa' && showCheckButton && (
        <MainButton
          variant="first"
          sx={{ mt: 2, maxWidth: 150 }}
          onClick={() => onSAQuestionCheck(true)}
          text="Check"
          isLoading={false}
        />
      )}

      {(!editable || checkCorrect) && question.explain && (
        <Box>
          <Divider
            sx={{
              my: 1,
              '&::before, &::after': {
                borderColor: 'text.secondary',
                opacity: 0.3,
              },
            }}
          >
            {checkCorrect && (
              <Typography
                variant="body1"
                sx={{
                  color: isCorrect
                    ? alpha(theme.palette.success.main, 0.8)
                    : alpha(theme.palette.error.main, 0.8),
                  alignItems: 'center',
                }}
              >
                {isCorrect ? <CheckIcon /> : <CloseIcon />}
              </Typography>
            )}
          </Divider>
          {question.explainImageUrl && (
            <ImageBox
              src={question.explainImageUrl}
              alt={`Explanation for question ${question.index}`}
              formatSize={formatSize}
              sx={{ float: 'left' }}
            />
          )}
          {RenderHTMLContent2(question.explain)}
        </Box>
      )}
    </Box>
  );
};

export default QuestionComponent;
//React.memo(QuestionComponent);
