import React, { useEffect, useRef } from 'react';
import {
  Typography,
  Box,
  Button,
  IconButton,
  Stack,
  Divider,
  useTheme,
  Drawer,
  Toolbar,
  alpha,
} from '@mui/material';
import { CreateExamPageProps, Question, Result, SectionType } from './type';
import ExamDetails from './ExamDetails';
import { handleWord } from './docUtils';
import QuestionComponent from './QuestionComponent';
import {
  CalculateScore,
  extractExamName,
  extractQuestions,
  getAnswerStats,
} from './extractQuestions';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { appActions } from '../../redux/slices/appSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useResponsive from '../../hooks/useResponsive';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { useNavigate } from 'react-router-dom';
import MainButton from '../Courses/MainButton';
import QuestionButtonList from './QuestionButtonList';
import NavigationBar from './NavigationBar';
import CountDownToResult from './CountDownToResult';
import CountDownToStart from './CountDownToStart';
import useAuth from '../../hooks/useAuth';
import { useSwipeable } from 'react-swipeable';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';

import TruncatedTextWithDialog from './TruncatedTextWithDialog';
import MenuIcon from '@mui/icons-material/Menu';
import { Cancel, CheckCircle, DoNotDisturb } from '@mui/icons-material';
import { green, pink } from '@mui/material/colors';
import LoadingDisplay from '../Home/Loading';

interface StatBoxProps {
  label: string;
  value: string;
  icon?: React.ReactNode;
  color?: string;
}

const StatBox: React.FC<StatBoxProps> = ({ label, value, icon, color }) => {
  const theme = useTheme(); // Access theme
  return (
    <Box
      width="50%"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        borderColor: 'text.secondary',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: 80,
        p: 0.5,
      }}
    >
      {icon}
      {color ? (
        <Typography
          sx={{
            color: color,
            fontWeight: 500,
          }}
        >
          {label}
        </Typography>
      ) : (
        <Typography
          sx={{
            color: 'primary.main',
            fontWeight: 700,
            background: `linear-gradient(to right, ${theme.palette.prize.first}, #ff7e5f)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent', // Transparent to show the gradient
          }}
        >
          {label}
        </Typography>
      )}

      {color ? (
        <Typography
          sx={{
            color: color,
            fontWeight: 500,
          }}
        >
          {value}
        </Typography>
      ) : (
        <Typography
          variant="subtitle2"
          sx={{
            color: 'primary.main',
            flexGrow: 1,
            alignContent: 'center',
            pb: 1,
            fontSize: '1.5rem',
            background: `linear-gradient(to right, ${theme.palette.prize.first}, #ff7e5f)`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {value}
        </Typography>
      )}
    </Box>
  );
};

//Custom professional icon button
export const OutlinedButton: React.FC<{
  icon: React.ReactNode;
  onClick: () => void;
}> = ({ icon, onClick }) => (
  <IconButton
    onClick={onClick}
    sx={{
      // border: '1px solid',
      // borderColor: 'primary.main',
      borderRadius: '50%',
      padding: 1,
      margin: 1,
      color: 'primary.main',
      width: 40,
      height: 40,
      // hover effect
      '&:hover': {
        backgroundColor: 'primary.light',
      },
    }}
  >
    {icon}
  </IconButton>
);

const CalculateRank = (
  result: Result | null,
  sortedResults: Result[] | undefined
) => {
  if (!result) return '_';
  if (!sortedResults) return '_';
  const rank = sortedResults.findIndex((r) => r.studentId === result.studentId);
  return `${rank + 1}/${sortedResults.length}`;
};

const AnswerResultPage: React.FC<CreateExamPageProps> = ({
  exam,
  mode = 'view',
  examInput,
  setExamInput,
  isTeacher,
  isNotOnDialog,
  result = null,
  sortedResults,
  isDataAllFetched,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { user } = useAuth();
  const [start, setStart] = React.useState(false);
  const isMobile = useResponsive('down', 'md');
  const [formatSize, setFormatSize] = React.useState<number>(
    isMobile ? 0.8 : 1
  );
  const swiperRef = useRef<SwiperCore>();
  //State to handle questions
  const [questions, setQuestions] = React.useState<Question[]>([]);
  const [openNav, setOpenNav] = React.useState<boolean>(() => false);
  const [activeQuestion, setActiveQuestion] = React.useState<Question | null>(
    null
  );
  const [viewMode, setViewMode] = React.useState<'none' | 'list' | 'single'>(
    'none'
  );
  const [viewAnswer, setViewAnswer] = React.useState(false);
  const [showResult, setShowResult] = React.useState(false);
  const timediff = useAppSelector((state) => state.app.timediff);
  const [loading, setLoading] = React.useState(true);

  useEffect(() => {
    if (mode !== 'view') {
      setViewAnswer(true);
      setShowResult(true);
      setStart(true);
      setLoading(false);
    }
  }, [mode]);

  useEffect(() => {
    if (isNotOnDialog) {
      setViewAnswer(true);
    }
  }, [isNotOnDialog]);

  useEffect(() => {
    if (mode === 'view') {
      if (!exam) return;
      //Check time
      if (!exam.isExercise) {
        if (!exam.executedAt || timediff === null) return;

        const startAt = new Date(exam.executedAt);
        const now = new Date(Date.now() + timediff * 1000);

        const diff = startAt.getTime() - now.getTime();
        if (diff <= 0) {
          let countDown = exam.duration * 60 + Math.floor(diff / 1000);
          if (countDown < 0) {
            setViewAnswer(true);
            setShowResult(true);
            setStart(true);
          }
        }
      } else {
        if (result) {
          setViewAnswer(true);
          setShowResult(true);
          setStart(true);
        } else {
          setViewAnswer(false);
        }
      }

      if (isDataAllFetched) setLoading(false);
    }
  }, [exam, timediff, result, mode, isDataAllFetched]);

  const handleToggleNavMenu = () => {
    //Set active question based on the swipe index
    isMobile &&
      setActiveQuestion(questions[swiperRef.current?.activeIndex || 0]);
    setOpenNav(!openNav);
  };

  useEffect(() => {
    if (!activeQuestion) return;
    if (swiperRef.current && activeQuestion !== undefined) {
      swiperRef.current.slideTo(questions.indexOf(activeQuestion));
    }
  }, [activeQuestion, questions]);

  //Create new array of sections include title and questions (questions are grouped by same section)
  const sections = questions.reduce((acc, question) => {
    const sectionIndex = acc.findIndex(
      (section) => section.title === question.section
    );
    if (sectionIndex === -1) {
      acc.push({
        title: question.section,
        content: question.sectionContent,
        questions: [question],
      });
    } else {
      acc[sectionIndex].questions.push(question);
    }
    return acc;
  }, [] as SectionType[]);

  useEffect(() => {
    if (examInput.questions.length > 0) {
      setQuestions(examInput.questions);
      setActiveQuestion(examInput.questions[0]);
      setViewMode('single');
    }
  }, [examInput.questions]);

  const showSingleQuestion = (question: Question) => {
    setActiveQuestion(question);
    setViewMode('single');
  };

  // const showListQuestions = async () => {
  //   setViewMode('list');
  // };

  const processWord = async (file: File) => {
    try {
      const html = await handleWord(file); // Get HTML content
      try {
        const newQuestions = await extractQuestions(html); // Extract questions from HTML content

        const examName = extractExamName(html); // Extract exam name from HTML content
        if (examName && examInput.title === '') {
          setExamInput((prevState) => ({
            ...prevState,
            title: examName,
          }));
        }

        // If mode is 'edit', check if questions format is the same as the current questions, like the number of questions, type, etc.
        // If not, show a error message 'Chỉ được phép thay đổi nội dung câu hỏi, không được thay đổi số lượng câu hỏi hoặc loại câu hỏi'
        // If yes, update the questions in the state

        if (mode === 'edit') {
          let isSame = true;
          if (newQuestions.length !== questions.length) {
            isSame = false;
          } else {
            for (let i = 0; i < newQuestions.length; i++) {
              if (newQuestions[i].type !== questions[i].type) {
                isSame = false;
                break;
              }
            }
          }

          if (!isSame) {
            dispatch(
              appActions.showNotification({
                variant: 'error',
                message:
                  'Chỉ được phép thay đổi nội dung câu hỏi, không được thay đổi số lượng câu hỏi hoặc loại câu hỏi',
              })
            );
            return;
          } else {
            //Assign questionId to new questions
            const updatedQuestions = newQuestions.map((question, index) => {
              question.questionId = questions[index].questionId;
              return question;
            });

            setQuestions(updatedQuestions);
          }
        } else {
          setQuestions(newQuestions);
        }

        setViewMode('single');
        setActiveQuestion(newQuestions[0]);
        setExamInput((prevState) => ({
          ...prevState,
          questions: newQuestions,
          isEdittingQuestion: true,
        }));
      } catch (error: any) {
        dispatch(
          appActions.showNotification({
            variant: 'error',
            message: error.message,
          })
        );
      }
    } catch (error) {
      console.error('Error processing file:', error);
    }
  };

  const handleUploadWord = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];

    if (!file) {
      return;
    }

    processWord(file);
  };

  const desktopHeight = isNotOnDialog ? '70vh' : '70vh';
  const mobileHeight = isNotOnDialog ? '95vh' : '95vh';

  const TopBarItems = (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
      }}
    >
      <TruncatedTextWithDialog
        sx={{ px: 1 }}
        content={exam?.title || ''}
        dialogContent={
          exam && <ExamDetails exam={exam} isNotOnDialog={false} />
        }
      />
      {isMobile && (
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleToggleNavMenu}
          sx={{
            color: (theme) => theme.palette.lighter.main,
            opacity: 0.6,
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
    </Box>
  );

  // if (loading) {
  //   return <LoadingDisplay loadingMessage="Đang tải chi tiết..." />;
  // }

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      sx={{
        minHeight: desktopHeight,
        flexDirection: {
          xs: 'column-reverse',
          md: 'row',
        },
        py: 1,
        width: '100%',
        maxWidth: '100%',
      }}
    >
      {loading ? (
        <LoadingDisplay loadingMessage="Đang tải chi tiết..." />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            flexGrow: 1,
            pt: 1,
            px: 0,
            height: isMobile ? mobileHeight : desktopHeight,
            width: '100%',
            maxWidth: isMobile ? '100%' : '75%',
            '&::-webkit-scrollbar': {
              height: '8px', // Set the height of the horizontal scrollbar
            },
          }}
        >
          {viewMode === 'none' && mode !== 'view' && (
            <Stack direction="column" width="100%">
              <Typography
                variant="examText"
                align="center"
                color="textSecondsary"
                fontWeight="bold"
              >
                Chọn file word có mẫu như sau
              </Typography>

              <iframe
                src={
                  'https://res.cloudinary.com/dmpprfvzq/image/upload/v1729657516/File_%C4%91%E1%BB%81_m%E1%BA%ABu_1_vyes4l.pdf' +
                  '#toolbar=0'
                }
                style={{
                  width: '100%',
                  height: isMobile ? '75vh' : '100%',
                  border: 'none',
                }}
                title={examInput.title}
              />
            </Stack>
          )}

          {viewMode === 'single' && (
            <Box width="100%" maxWidth="100%" position="relative" height="100%">
              <Box
                mb={1}
                sx={{
                  px: 0,
                  height: isMobile ? '100%' : 'calc(100% - 60px)',
                  maxWidth: '100%',
                }}
              >
                {mode === 'view' && !viewAnswer ? ( //&& exam.isExercise
                  <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    sx={{
                      minHeight: desktopHeight,
                    }}
                  >
                    <Stack direction="column" spacing={1} minWidth={200}>
                      {/* <ExamDetails
                      exam={exam}
                      mode={mode}
                      examInput={examInput}
                      setExamInput={setExamInput}
                    /> */}
                      <MainButton
                        onClick={() => {
                          // Open /examming/${exam.examId} in new tab, not navigate
                          // window.open(`/examming/${exam.examId}`);
                          navigate(`/examming/${exam.examId}`);
                        }}
                        text="Làm bài"
                        variant="first"
                        isLoading={false}
                        icon={<KeyboardArrowRightIcon />}
                      />
                      {/* {(result || (!exam.isExercise && !start)) && (
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() => {
                            setViewAnswer(true);
                          }}
                          startIcon={<TextSnippetIcon />}
                        >
                          Xem kết quả
                        </Button>
                      )} */}
                    </Stack>
                  </Box>
                ) : (
                  <>
                    {!start && (
                      <CountDownToStart
                        exam={exam}
                        timediff={timediff || 0}
                        user={user}
                        onStart={setStart}
                      />
                    )}

                    {!showResult && start && (
                      <CountDownToResult
                        exam={exam}
                        timediff={timediff || 0}
                        //isTeacher={isTeacher}
                        onShowResult={setShowResult}
                      />
                    )}

                    {activeQuestion && start && !isMobile && (
                      <QuestionComponent
                        question={
                          showResult
                            ? activeQuestion //remove the correctAnswer and explane if showResult is true
                            : {
                                ...activeQuestion,
                                correctAnswer: '',
                                explain: '',
                                explainImageUrl: '',
                              }
                        }
                        editable={false}
                        sx={{
                          maxHeight: '100%',
                          overflow: 'auto',
                        }}
                        answer={
                          result?.answers.find(
                            (answer) =>
                              answer.questionId === activeQuestion.questionId
                          )?.answer
                        }
                        checkCorrect={
                          result?.answers.find(
                            (answer) =>
                              answer.questionId === activeQuestion.questionId
                          )?.check
                        }
                        formatSize={formatSize}
                      />
                    )}

                    {activeQuestion && start && isMobile && (
                      <Swiper
                        onSwiper={(swiperInstance) => {
                          swiperRef.current = swiperInstance;
                        }}
                        style={{
                          height: '100%', // Each slide takes the full height
                        }}
                      >
                        {questions.map((activeQuestion) => (
                          <SwiperSlide
                            key={activeQuestion.questionId}
                            style={{
                              height: '100%', // Each slide takes the full height
                            }}
                          >
                            <QuestionComponent
                              question={
                                showResult
                                  ? activeQuestion //remove the correctAnswer and explane if showResult is true
                                  : {
                                      ...activeQuestion,
                                      correctAnswer: '',
                                      explain: '',
                                      explainImageUrl: '',
                                    }
                              }
                              editable={false}
                              sx={{
                                maxHeight: '100%',
                                overflow: 'auto',
                              }}
                              answer={
                                result?.answers.find(
                                  (answer) =>
                                    answer.questionId ===
                                    activeQuestion.questionId
                                )?.answer
                              }
                              checkCorrect={
                                result?.answers.find(
                                  (answer) =>
                                    answer.questionId ===
                                    activeQuestion.questionId
                                )?.check
                              }
                              formatSize={formatSize}
                            />
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    )}
                  </>
                )}
              </Box>
              {/* Bottom Navigation to navigate between questions
            The design would be "Previous" and "Next" buttons at left and right
            Rounded question number buttons in the middle (show atmost 5 at the same time) */}
              {!isMobile && (
                <NavigationBar
                  activeQuestion={activeQuestion}
                  questions={questions}
                  setActiveQuestion={setActiveQuestion}
                  setFormatSize={setFormatSize}
                />
              )}
            </Box>
          )}
        </Box>
      )}

      {/* Right Panel */}
      <Box
        sx={{
          minWidth: 240,
          width: {
            xs: '100%',
            md: '20%',
          },
          px: isMobile ? 0 : 1,
          maxHeight: isMobile ? 'none' : desktopHeight,
          overflow: isMobile ? 'hidden' : 'auto',
        }}
      >
        {isTeacher && (mode === 'edit' || mode === 'create') ? (
          //If activeFile is null, show upload button, else show reload button
          <Button
            variant="contained"
            component="label"
            sx={{ mb: 2, width: '100%' }}
          >
            <TextSnippetIcon sx={{ mr: 1 }} />
            Tải file Word
            <input
              type="file"
              name="answerURL"
              accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document"
              onChange={(e) => {
                handleUploadWord(e); // Call your existing handler
                e.target.value = ''; // Reset the input value to allow the same file to be chosen again
              }}
              hidden
            />
          </Button>
        ) : (
          <></>
        )}

        {viewMode !== 'none' && (
          <Box px={isMobile ? 1 : 0}>
            {!(mode === 'edit' || mode === 'create') &&
              result &&
              viewAnswer &&
              start &&
              showResult && (
                <>
                  <Stack direction="row">
                    <StatBox
                      label="Điểm"
                      value={CalculateScore(
                        result.answers ?? [],
                        questions
                      ).toString()} // Show score if result is not null
                    />
                    <StatBox
                      label="Xếp hạng"
                      value={CalculateRank(result, sortedResults)}
                    />
                  </Stack>
                  <Divider
                    sx={{
                      mb: 1,
                      backgroundColor: 'text.secondary',
                      opacity: 0.3,
                    }}
                  />
                  <Stack direction="row">
                    <StatBox
                      label="Đúng"
                      value={getAnswerStats(
                        result.answers ?? [],
                        questions
                      )[0].toString()}
                      icon={
                        <CheckCircle
                          sx={{ color: alpha(theme.palette.success.main, 1) }}
                        />
                      }
                      color={alpha(theme.palette.success.main, 1)}
                    />
                    <StatBox
                      label="Sai"
                      value={getAnswerStats(
                        result.answers ?? [],
                        questions
                      )[1].toString()}
                      icon={
                        <Cancel
                          sx={{ color: alpha(theme.palette.error.main, 0.8) }}
                        />
                      }
                      color={alpha(theme.palette.error.main, 0.8)}
                    />
                    <StatBox
                      label="Bỏ qua"
                      value={getAnswerStats(
                        result.answers ?? [],
                        questions
                      )[2].toString()}
                      icon={
                        <DoNotDisturb
                          sx={{
                            color: alpha(theme.palette.secondary.main, 0.8),
                          }}
                        />
                      }
                      color={alpha(theme.palette.secondary.main, 0.8)}
                    />
                  </Stack>
                </>
              )}
            {/* Section for questions */}
            <Box display={isMobile ? 'none' : 'block'}>
              <QuestionButtonList
                sections={sections}
                isMobile={isMobile || false}
                activeQuestion={activeQuestion}
                showSingleQuestion={showSingleQuestion}
                check={showResult && (viewAnswer || !exam?.isExercise) && start} //(viewAnswer || !exam?.isExercise) && result !== null
                result={result || undefined}
              />
              <Divider
                sx={{
                  mb: 1,
                  backgroundColor: 'text.secondary',
                  opacity: 0.3,
                }}
              />
            </Box>
            <Box px={isMobile ? 0 : 1}>
              {!isMobile && (
                <ExamDetails
                  exam={exam}
                  mode={mode}
                  examInput={examInput}
                  setExamInput={setExamInput}
                  isNotOnDialog={isNotOnDialog}
                />
              )}
            </Box>
          </Box>
        )}
        {isMobile && TopBarItems}
        {isMobile && (
          <Drawer
            sx={{
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: isNotOnDialog
                  ? 'background.default'
                  : 'background.paper',
                border: 'none',
              },
            }}
            variant="persistent"
            anchor="right"
            open={openNav}
            onClose={handleToggleNavMenu}
          >
            <Toolbar
              disableGutters
              sx={{
                minHeight: '50px !important',
                px: 2,
              }}
            >
              {TopBarItems}
            </Toolbar>
            <Box
              sx={{
                backgroundColor: isNotOnDialog
                  ? 'background.default'
                  : 'background.paper',
                px: isMobile ? 3 : 1,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                height: '100%',
                pb: 1.5,
              }}
            >
              <QuestionButtonList
                sections={sections}
                isMobile={isMobile || false}
                activeQuestion={activeQuestion}
                showSingleQuestion={showSingleQuestion}
                check={showResult && (viewAnswer || !exam?.isExercise) && start}
                result={result || undefined}
              />
            </Box>
          </Drawer>
        )}
      </Box>
    </Box>
  );
};

export default AnswerResultPage;
