import { useEffect, useState } from 'react';
import { TextField, Button, Box } from '@mui/material';
import { fetchSubFolderById } from '../../api';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

interface SubFolderDialogItemProps {
  onSaveClick: (name: string) => void;
  onClose: () => void;
  subFolderId?: string;
  label?: string;
  placeholder?: string;
}

const SubFolderDialogItem: React.FC<SubFolderDialogItemProps> = ({
  onSaveClick,
  onClose,
  subFolderId,
  label = 'Folder Name',
  placeholder = 'New Folder',
}) => {
  const [title, setTitle] = useState<string>(placeholder || '');

  const handleSaveClick = () => {
    if (title.trim()) {
      onSaveClick(title);
    }
  };

  // fetch subfolder if subFolderId is provided
  useEffect(() => {
    const loadSubFolder = async () => {
      try {
        if (!subFolderId) return;

        const res = await fetchSubFolderById(subFolderId);
        setTitle(res.data.title);
      } catch (err) {
        console.error('Error fetching exam:', err);
      }
    };

    loadSubFolder();
  }, [subFolderId]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        padding: 2,
        pb: 0,
      }}
    >
      <TextField
        label={label || 'Title'}
        variant="standard"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        fullWidth
        InputProps={{
          startAdornment: <InfoOutlinedIcon style={{ marginRight: 8 }} />,
        }}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          variant="contained"
          color="primary"
          disabled={!title.trim()}
          onClick={handleSaveClick}
          sx={{ mx: 1 }}
        >
          Save
        </Button>
        <Button variant="outlined" onClick={onClose}>
          Close
        </Button>
      </Box>
    </Box>
  );
};

export default SubFolderDialogItem;
