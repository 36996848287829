import React, { useRef, useState } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  Button,
  Stack,
  Typography,
  IconButton,
  InputAdornment,
  CircularProgress,
} from '@mui/material';
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import PersonOutlinedIcon from '@mui/icons-material/PersonOutlined';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SchoolIcon from '@mui/icons-material/School';
import CloseIcon from '@mui/icons-material/Close';

// RHF
import FormProvider from '../RHF/FormProvider';
import RHFTextField from '../RHF/RHFTextField';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, SubmitHandler } from 'react-hook-form';

// App Component
import { StyledPaper } from './style';

// Data
import { StudentInfo, UpdateProfileData } from '../../model/Student';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { MODAL, appActions } from '../../redux/slices/appSlice';
import { logout, updateProfile } from '../../api';
import { authActions } from '../../redux/slices/authSlice';
import MainButton from '../../pages/Courses/MainButton';
import { LogoutOutlined } from '@mui/icons-material';
import { googleLogout } from '@react-oauth/google';

type PropsType = {
  user: StudentInfo;
  showUpdateProfileLogout?: boolean;
  onClose?: () => void;
};

const UpdateProfileDialog: React.FC<PropsType> = ({
  user,
  showUpdateProfileLogout,
  onClose,
}) => {
  const dispatch = useAppDispatch();

  const avatarRef = useRef<HTMLInputElement>(null);
  const [selectedAvatarImg, setSelectedAvatarImg] = useState<null | string>(
    null
  );
  const [loading, setLoading] = useState<boolean>(false); // Add loading state
  const { showUpdateProfileModal } = useAppSelector((state) => state.app);
  //const { showUpdateProfileLogout } = useAppSelector((state) => state.app);

  const handleCloseModal = () => {
    dispatch(appActions.closeModal(MODAL.UPDATE_PROFILE));
  };

  const handleLogout = async () => {
    try {
      await logout();
      googleLogout();
      dispatch(authActions.logout());
    } catch (err) {
      dispatch(
        appActions.showNotification({
          variant: 'error',
          message: 'Đăng xuất thất bại',
        })
      );
    }
  };

  const handleChangeAvatar = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file && event.target.files) {
      setSelectedAvatarImg(URL.createObjectURL(event.target.files[0]));
    }
  };

  // const handleChangeAvatar = async (
  //   event: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   const file = event.target.files?.[0];
  //   if (!file) return;

  //   const img = new Image();
  //   img.src = URL.createObjectURL(file);

  //   img.onload = () => {
  //     // Set up a canvas with 150x150 dimensions
  //     const canvas = document.createElement('canvas');
  //     canvas.width = 150;
  //     canvas.height = 150;
  //     const ctx = canvas.getContext('2d');

  //     if (ctx) {
  //       // Draw the image to fill the canvas and crop to center
  //       ctx.drawImage(
  //         img,
  //         (img.width - img.height) / 2, // start x to center crop
  //         0, // start y (centered vertically)
  //         img.height, // square dimension
  //         img.height,
  //         0, // target x
  //         0, // target y
  //         150, // target width
  //         150 // target height
  //       );

  //       // Convert canvas to a blob and create an object URL
  //       canvas.toBlob((blob) => {
  //         if (blob) {
  //           const croppedUrl = URL.createObjectURL(blob);
  //           setSelectedAvatarImg(croppedUrl);

  //           // Optionally revoke the original object URL to free up memory
  //           URL.revokeObjectURL(img.src);
  //         }
  //       }, 'image/jpeg');
  //     }
  //   };
  // };

  const schema = yup.object().shape({
    name: yup.string().required('Vui lòng nhập tên'),
    email: yup
      .string()
      .email('Vui lòng nhập email hợp lệ')
      .required('Vui lòng nhập email'),
    school: yup.string().required('Vui lòng nhập trường học'),
  });

  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      name: user.name,
      email: user.email || '',
      school: user.school || '',
    },
    resolver: yupResolver(schema),
  });

  const submitFormHandler: SubmitHandler<UpdateProfileData> = async (
    data: UpdateProfileData
  ) => {
    setLoading(true); // Set loading to true when submission starts
    try {
      const formData = new FormData();
      if (avatarRef.current?.files)
        formData.append('image', avatarRef.current.files[0]);

      for (const [key, value] of Object.entries(data)) {
        formData.append(key, value.toString());
      }

      const storedUserData = localStorage.getItem('userId');
      const storedToken = localStorage.getItem('token');
      if (storedUserData !== null && storedToken !== null) {
        const id = storedUserData;
        formData.append('_id', id);
        formData.append('token', storedToken);
      }

      // console.log('formData', formData);
      const { data: response } = await updateProfile(formData);
      dispatch(authActions.setUser(response.data.user));
      dispatch(appActions.closeModal(MODAL.UPDATE_PROFILE));

      // if (!showUpdateProfileLogout) {
      //   dispatch(
      //     appActions.showNotification({
      //       variant: 'success',
      //       message: 'Cập nhật thông tin thành công',
      //     })
      //   );
      // }
      setSelectedAvatarImg(null);
    } catch (err) {
      dispatch(appActions.closeModal(MODAL.UPDATE_PROFILE));
      if (!showUpdateProfileLogout) {
        dispatch(
          appActions.showNotification({
            variant: 'error',
            message: 'Cập nhật thông tin thất bại',
          })
        );
      }
    } finally {
      setLoading(false); // Set loading to false after submission completes
      if (onClose) {
        onClose();
      }
    }
  };

  return (
    <Dialog
      open={
        showUpdateProfileLogout !== undefined
          ? showUpdateProfileLogout
          : showUpdateProfileModal
      }
      onClose={handleCloseModal}
    >
      <StyledPaper
        sx={{
          maxWidth: 360,
        }}
      >
        <Typography
          variant="h3"
          sx={{ padding: (theme) => theme.spacing(1, 3) }}
          textAlign="center"
        >
          Thông tin
        </Typography>
        <Stack direction="column" alignItems="center" mb={0} mt={1}>
          <Box position="relative" color="secondary">
            {/* <Avatar
              sx={{
                width: 80,
                height: 80,
              }}
              src={selectedAvatarImg ? selectedAvatarImg : user.avatar}
            /> */}
            <img
              src={selectedAvatarImg ? selectedAvatarImg : user.avatar}
              alt="Avatar"
              style={{
                width: 80,
                height: 80,
                borderRadius: '50%',
                objectFit: 'cover',
              }}
              referrerPolicy="no-referrer"
            />
            <IconButton
              component="label"
              sx={{
                position: 'absolute',
                bottom: -4,
                right: -4,
                padding: '3px',
              }}
            >
              <CameraAltIcon sx={{ width: 25, height: 25 }} />
              <input
                type="file"
                hidden
                accept="image/*"
                ref={avatarRef}
                onChange={handleChangeAvatar}
              />
            </IconButton>
          </Box>
        </Stack>
        <Box sx={{ p: 3, borderRadius: '30px 30px 0 0' }}>
          <FormProvider<UpdateProfileData>
            methods={methods}
            handler={submitFormHandler}
          >
            <RHFTextField
              name="email"
              label="Email"
              startAdornment={
                <InputAdornment position="start">
                  <MailOutlineIcon
                    fontSize="small"
                    sx={{ color: 'text.primary' }}
                  />
                </InputAdornment>
              }
              placeholder="Nhập email"
              disabled
            />
            <RHFTextField
              name="name"
              label="Tên đầy đủ"
              startAdornment={
                <InputAdornment position="start">
                  <PersonOutlinedIcon
                    fontSize="small"
                    sx={{ color: 'text.primary' }}
                  />
                </InputAdornment>
              }
              placeholder="Nhập tên đầy đủ"
            />

            <RHFTextField
              name="school"
              label="Trường học"
              startAdornment={
                <InputAdornment position="start">
                  <SchoolIcon fontSize="small" sx={{ color: 'text.primary' }} />
                </InputAdornment>
              }
              placeholder="Nhập trường học"
            />

            {/* <Button
              variant="contained"
              type="submit"
              sx={{ display: 'block', mx: 'auto', mt: 3 }}
              disabled={loading} // Disable button while loading
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Xác nhận'
              )}
            </Button> */}
            <MainButton
              text="Xác nhận"
              isLoading={loading}
              onClick={methods.handleSubmit(submitFormHandler)}
              sx={{ mx: 'auto', mt: 3 }}
            />
            {showUpdateProfileLogout && (
              <Button
                fullWidth
                variant="outlined"
                sx={{ mx: 'auto', mt: 1 }}
                onClick={handleLogout}
                startIcon={<LogoutOutlined />}
              >
                Đăng xuất
              </Button>
            )}
          </FormProvider>
        </Box>
        {!showUpdateProfileLogout && (
          <IconButton
            aria-label="close"
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
            onClick={handleCloseModal}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )}
      </StyledPaper>
    </Dialog>
  );
};

export default UpdateProfileDialog;
