import React, { useState, useEffect } from 'react';
import { Box, Container, Typography, Grid } from '@mui/material';
import Course from './Course'; // Import the Course component
import useAuth from '../../hooks/useAuth';
import { fetchAllCourses } from '../../api'; // Import the fetchAllCourses function
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/index';
import { setCourses } from '../../redux/slices/courseSlice';
import { useLocation } from 'react-router-dom';
import ErrorDisplay from './Error';
import LoadingDisplay from './Loading';
import paths from '../../constants/paths';
import NotFoundPage from '../Error/NotFoundPage';
import { Exam } from '../Exams/type';
// import Slider from '@ant-design/react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

const SubcriptionsPage: React.FC = () => {
  const location = useLocation();
  const { user } = useAuth();
  const dispatch = useDispatch();
  const courses = useSelector((state: RootState) => state.course.courses);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [filteredCourses, setFilteredCourses] = useState(courses);
  const [exams, setExams] = useState<Exam[]>([]);

  const isTeacher =
    (location.pathname === paths.MYCOURSE ||
      location.pathname === paths.MYINFO) &&
    !!user &&
    (user.role === 'admin' || user.role === 'teacher');

  // Check if path is /mycourse then filter courses by user id
  useEffect(() => {
    setLoading(true);

    setTimeout(() => {
      setLoading(false); // Set loading to false after the delay
    }, 500); // Ensure loading lasts for at least 0.5 seconds
  }, []);

  // useEffect(() => {
  //   //setLoading(true);
  //   const loadCourses = async () => {
  //     try {
  //       const data = await fetchAllCourses();
  //       dispatch(setCourses(data)); // Dispatch setCourses action

  //     } catch (err) {
  //       console.error('Error fetching courses:', err);
  //       setError('Failed to load courses');
  //     } finally {
  //       //setLoading(false);
  //     }
  //   };

  //   loadCourses();
  // }, [dispatch]);

  // if (location.pathname !== paths.HOME && !user) {
  //   return <NotFoundPage message="Trang chỉ dành cho tài khoản đăng nhập" />;
  // }

  if (!user) {
    return <NotFoundPage message="Trang chỉ dành cho tài khoản đăng nhập" />;
  }

  return (
    <Container maxWidth={false}>
      <Box sx={{ position: 'relative', mb: 4, mt: 2 }}>
        {loading && (
          <LoadingDisplay loadingMessage="Đang tải chi tiết khóa học..." />
        )}

        {error && (
          <ErrorDisplay errorMessage="Đã xảy ra lỗi. Vui lòng thử lại sau." />
        )}
        <Typography variant="h3" textAlign="center">
          Khoá đã đăng ký
        </Typography>
        {user?.buyCourses?.length === 0 && (
          <Typography variant="h6" textAlign="center">
            Bạn chưa đăng ký khoá học nào
          </Typography>
        )}
        {!loading && !error && (
          <>
            <Grid container spacing={3}>
              {user?.buyCourses?.map((course) => (
                <Grid
                  item
                  xs={12} // Full width on extra small screens
                  sm={6} // Half width on small screens and above
                  md={4} // One-third width on medium screens and above (adjust as needed)
                  lg={4} // One-fourth width on large screens and above (adjust as needed)
                  xl={2}
                  key={course.courseId}
                >
                  <Course
                    courseId={course.courseId}
                    image={course.image}
                    title={course.title}
                    description={course.description}
                    favorites={course.favorites}
                    members={course.members}
                    exams={course.exams}
                    videos={course.videos}
                    pdfs={course.pdfs}
                    createdAt={course.createdAt}
                    lastUpdatedAt={course.lastUpdatedAt}
                    createdBy={course.createdBy}
                    price={course.price}
                    contactLink={course.contactLink}
                    isTeacher={isTeacher}
                    rates={course.rates}
                    items={course.items} // Add missing items property
                    learningObjectives={course.learningObjectives} // Add missing learningObjectives property
                    subject={course.subject} // Add missing subject property
                  />
                </Grid>
              ))}
            </Grid>
          </>
        )}
      </Box>
    </Container>
  );
};

export default SubcriptionsPage;
