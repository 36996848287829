import React from 'react';
import { Box, CircularProgress, Container, Typography } from '@mui/material';

type LoadingDisplayProps = {
  loadingMessage?: string; // Optional prop for custom loading messages
};

const LoadingDisplay: React.FC<LoadingDisplayProps> = ({
  loadingMessage = 'Đang tải chi tiết khóa học...', // Default loading message
}) => {
  return (
    <Container
      maxWidth="md"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        minHeight: '50vh',
      }}
    >
      <Box textAlign="center">
        <CircularProgress />
        <Typography variant="h6" sx={{ marginTop: 2 }}>
          {loadingMessage}
        </Typography>
      </Box>
    </Container>
  );
};

export default LoadingDisplay;
