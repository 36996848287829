import React, { useEffect, useState } from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  Box,
  Stack,
  Divider,
} from '@mui/material';
import { Question } from './type';
import { RenderHTMLContent, RenderHTMLContent2 } from './extractQuestions';
import { green, pink, yellow } from '@mui/material/colors';
import ImageBox from './ImageBox';

// Props for the MTCQuestion component
interface MTCQuestionProps {
  question: Question;
  editable?: boolean;
  onAnswerChange?: (answer: string) => void;
  answer?: string;
  formatSize?: number;
  checkCorrect?: boolean;
}

interface CustomFormControlLabelProps {
  value: string;
  label: React.ReactNode;
  formatSize?: number;
  checkCorrect?: boolean;
  correctAnswer?: string;
  answer?: string;
}

// Props for the QuestionPart component
interface QuestionPartProps {
  partLabel: string; // 'a', 'b', 'c', 'd'
  questionContent: string;
  formatSize?: number;
  questionImageUrl?: string;
}

const QuestionPart: React.FC<QuestionPartProps> = ({
  partLabel,
  questionContent,
  questionImageUrl,
  formatSize = 0.8,
}) => {
  return (
    <Box
      // mb={0.5}
      // mr={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
    >
      <Box
        sx={{
          position: 'relative',
          alignItems: 'center',
          display: 'flex',
        }}
      >
        <Typography variant="examText">
          {/* <strong>{partLabel}.</strong> {RenderHTMLContent2(questionContent)} */}
          {RenderHTMLContent2(questionContent, partLabel + '.')}
        </Typography>
        {questionImageUrl && (
          // <Box
          //   component="img"
          //   src={questionImageUrl}
          //   alt={`Question ${questionImageUrl}`}
          //   onLoad={handleImageLoad}
          //   sx={{
          //     float: 'right',
          //     marginLeft: 2,
          //     marginBottom: 2,
          //     width: imageSize.width * formatSize + 'px',
          //     height: 'auto',
          //   }}
          // />
          <ImageBox
            src={questionImageUrl}
            alt={`Question ${questionImageUrl}`}
            formatSize={formatSize}
          />
        )}
      </Box>
    </Box>
  );
};

const CustomFormControlLabel: React.FC<CustomFormControlLabelProps> = ({
  value,
  label,
  formatSize = 0.8,
}) => {
  return (
    <FormControlLabel
      value={value}
      control={
        <Radio
          sx={{
            '& .MuiSvgIcon-root': {
              fontSize: formatSize + 0.5 + 'rem',
            },
            color: yellow[800],
            '&.Mui-checked': {
              color: yellow[700],
            },
          }}
        />
      }
      label={<Typography variant="examText">{label}</Typography>}
      sx={{ py: formatSize - 0.5 }}
    />
  );
};

const MTCQuestion: React.FC<MTCQuestionProps> = ({
  question,
  editable,
  onAnswerChange,
  answer,
  formatSize,
  checkCorrect = false,
}) => {
  // Set the initial selected answer based on the question's correct answer
  const [selectedAnswer, setSelectedAnswer] = useState<string>('');

  // Initialize answers based on the question's correct answers
  useEffect(() => {
    if (answer !== undefined) {
      setSelectedAnswer(answer || '');
    } else {
      setSelectedAnswer(question.correctAnswer);
    }
  }, [question.correctAnswer, editable, answer, checkCorrect]);

  // Handle change of selected answer
  const handleAnswerChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (checkCorrect) return;
    if (!editable) return;
    setSelectedAnswer(event.target.value);
    onAnswerChange && onAnswerChange(event.target.value);
  };
  return (
    <Box>
      {/* Render multiple choice options if the question type is 'mcq' */}
      {question.type === 'mcq' && (
        <RadioGroup
          value={selectedAnswer}
          onChange={handleAnswerChange}
          sx={{ width: '100%' }}
        >
          <CustomFormControlLabel
            value="A"
            label={
              <QuestionPart
                partLabel="A"
                questionContent={question.questionA}
                questionImageUrl={question.questionAImageUrl}
                formatSize={formatSize}
              />
            }
            formatSize={formatSize}
            checkCorrect={checkCorrect}
            correctAnswer={question.correctAnswer}
            answer={answer}
          />
          <CustomFormControlLabel
            value="B"
            label={
              <QuestionPart
                partLabel="B"
                questionContent={question.questionB}
                questionImageUrl={question.questionBImageUrl}
                formatSize={formatSize}
              />
            }
            formatSize={formatSize}
            checkCorrect={checkCorrect}
            correctAnswer={question.correctAnswer}
            answer={answer}
          />
          <CustomFormControlLabel
            value="C"
            label={
              <QuestionPart
                partLabel="C"
                questionContent={question.questionC}
                questionImageUrl={question.questionCImageUrl}
                formatSize={formatSize}
              />
            }
            formatSize={formatSize}
            checkCorrect={checkCorrect}
            correctAnswer={question.correctAnswer}
            answer={answer}
          />
          <CustomFormControlLabel
            value="D"
            label={
              <QuestionPart
                partLabel="D"
                questionContent={question.questionD}
                questionImageUrl={question.questionDImageUrl}
                formatSize={formatSize}
              />
            }
            formatSize={formatSize}
            checkCorrect={checkCorrect}
            correctAnswer={question.correctAnswer}
            answer={answer}
          />
        </RadioGroup>
      )}
    </Box>
  );
};

export default MTCQuestion;
