import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  CircularProgress,
} from '@mui/material';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import {
  defaultExam,
  defaultExamInput,
  Exam,
  ExamInput,
  Question,
  Result,
} from './type';
import {
  addOneToExamTimes,
  addQuestion,
  createNewExam,
  fetchExamById,
  fetchResultByExamId,
  fetchResultByStudentIdAndExamId,
  updateExamById,
  updateQuestionById,
} from '../../api';
import AnswerResultPage from './AnswerResultPage';
import { useDispatch } from 'react-redux';
import {
  addCourseItem,
  updateCourseItem,
} from '../../redux/slices/courseItemSlice';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import CloseIcon from '@mui/icons-material/Close';
import useAuth from '../../hooks/useAuth';
import useResponsive from '../../hooks/useResponsive';
import LeaderBoardPage from './LeaderBoardPage';
import { sortResults } from './extractQuestions';

interface ExamTabsProps {
  examId?: string;
  mode?: 'view' | 'edit' | 'create';
  courseId?: string;
  parentFolderId?: string;
  closeDialog?: () => void;
  isTeacher?: boolean;
  examTemplate?: Exam;
}

const fullSteps = [
  {
    label: 'Đề thi',
    icon: <AssignmentIcon />,
  },
  {
    label: 'Bảng xếp hạng',
    icon: <LeaderboardIcon />,
  },
];

const ExamTabs: React.FC<ExamTabsProps> = ({
  examId,
  mode = 'view',
  courseId = '',
  parentFolderId = '',
  closeDialog = () => {},
  isTeacher = false,
  examTemplate,
}) => {
  const { examId: urlExamId } = useParams<{ examId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);
  const [exam, setExam] = useState<Exam | null>(defaultExam);
  const [examInput, setExamInput] = useState(defaultExamInput);
  const { user } = useAuth();
  const [isDataFilled, setIsDataFilled] = useState<boolean>(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [progress, setProgress] = useState(0);
  const [result, setResult] = useState<Result | null>(null);
  const isMobile = useResponsive('down', 'md');
  const [results, setResults] = useState<Result[]>([]);
  const [isDataAllFetch, setIsDataAllFetch] = useState(false);
  const [isExamDataFetch, setIsExamDataFetch] = useState(false);
  const [isResultDataFetch, setIsResultDataFetch] = useState(false);

  const sortedResults = sortResults(results, exam);

  useEffect(() => {
    if (exam?.examId) {
      fetchResultByExamId(exam.examId).then((data) => {
        if (data) {
          setResults(data);
        }
      });
    }
  }, [exam?.examId]);

  useEffect(() => {
    if (examTemplate && examTemplate.examId) {
      setExam(examTemplate);

      setExamInput({
        title: examTemplate.title || '',
        duration: examTemplate.duration || '',
        subject: examTemplate.subject || '',
        executedAt: examTemplate.executedAt
          ? new Date(examTemplate.executedAt)
          : null,
        questions: examTemplate.questions || [],
        isExercise: examTemplate.isExercise || false,
        onlyForMembers: examTemplate.onlyForMembers || false,
      } as ExamInput);
    }
  }, [examTemplate]);

  useEffect(() => {
    setIsDataFilled(
      !(
        examInput.title &&
        examInput.duration &&
        examInput.subject &&
        examInput.questions.length > 0
      )
    );
  }, [examInput]);

  //Remove the text after the last slash
  const path = location.pathname.substring(
    0,
    location.pathname.lastIndexOf('/')
  );

  const isNotOnDialog = path.includes('answer') || path.includes('leaderboard');

  // const height = isNotOnDialog ? '90vh' : '80vh';

  // Fetch exam data based on the examId
  useEffect(() => {
    const loadExam = async () => {
      try {
        const id = examId || urlExamId;
        if (!id) return;
        if (!exam || exam.examId !== id) {
          const res = await fetchExamById(id);

          const data = res.data;
          setExam(data);
          setIsExamDataFetch(true);
          setIsDataAllFetch(isResultDataFetch);
          setExamInput({
            title: data.title || '',
            duration: data.duration || '',
            subject: data.subject || '',
            executedAt: data.executedAt ? new Date(data.executedAt) : null,
            questions: data.questions || [],
            isExercise: data.isExercise || false,
            onlyForMembers: data.onlyForMembers || false,
          } as ExamInput);

          await addOneToExamTimes(id);
        }
      } catch (err) {
        console.error('Error fetching exam:', err);
      }
    };

    loadExam();
  }, [examId, urlExamId, isResultDataFetch, exam]);

  //Fetch result data from api based on studentId and examId
  //fetchResultByStudentIdAndExamId
  useEffect(() => {
    const loadResult = async () => {
      try {
        if (!exam) return;
        if (!user || !user._id) {
          setIsResultDataFetch(true);
          setIsDataAllFetch(isExamDataFetch);
          return;
        }
        const res = await fetchResultByStudentIdAndExamId(
          user._id,
          exam.examId
        );
        const data = res.data;
        if (data) {
          //Add field check to each answer
          data.answers = data.answers.map((answer: any) => ({
            ...answer,
            check: true,
          }));
          setResult(data);
        }
        setIsResultDataFetch(true);
        setIsDataAllFetch(isExamDataFetch);
      } catch (err) {
        console.error('Error fetching result:', err);
      }
    };

    loadResult();
  }, [exam, user, isExamDataFetch]);

  // Determine active tab based on URL
  useEffect(() => {
    if (location.pathname.includes('leaderboard')) {
      setActiveTab(1);
    } else if (location.pathname.includes('answer')) {
      setActiveTab(0);
    }
  }, [location.pathname]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
    const id = examId || urlExamId;
    if (isNotOnDialog) {
      switch (newValue) {
        case 0:
          navigate(`/answer/${id}`);
          break;
        case 1:
          navigate(`/leaderboard/${id}`);
          break;
        default:
          break;
      }
    }
  };

  const handleSave = async () => {
    setLoadingButton(true);
    setProgress(0); // Reset progress to 0 at the start

    const totalQuestions = examInput.questions.length;

    const processQuestionData = (question: Question, examId: string) => {
      const questionData = new FormData();
      questionData.append('section', question.section);
      questionData.append('sectionContent', question.sectionContent);
      questionData.append('index', question.index.toString());
      questionData.append('type', question.type);
      questionData.append('correctAnswer', question.correctAnswer);
      questionData.append('reuseContent', question.reuseContent);
      questionData.append('questionImageUrl', question.questionImageUrl);
      questionData.append('explainImageUrl', question.explainImageUrl);
      questionData.append(
        'reuseContentImageUrl',
        question.reuseContentImageUrl
      );
      questionData.append('questionAImageUrl', question.questionAImageUrl);
      questionData.append('questionBImageUrl', question.questionBImageUrl);
      questionData.append('questionCImageUrl', question.questionCImageUrl);
      questionData.append('questionDImageUrl', question.questionDImageUrl);
      question.questionImage &&
        questionData.append('questionImage', question.questionImage);
      question.explainImage &&
        questionData.append('explainImage', question.explainImage);
      question.reuseContentImage &&
        questionData.append('reuseContentImage', question.reuseContentImage);
      question.questionAImage &&
        questionData.append('questionAImage', question.questionAImage);
      question.questionBImage &&
        questionData.append('questionBImage', question.questionBImage);
      question.questionCImage &&
        questionData.append('questionCImage', question.questionCImage);
      question.questionDImage &&
        questionData.append('questionDImage', question.questionDImage);

      questionData.append('examId', examId);
      questionData.append('question', question.question);
      questionData.append('explain', question.explain);
      questionData.append('questionA', question.questionA);
      questionData.append('questionB', question.questionB);
      questionData.append('questionC', question.questionC);
      questionData.append('questionD', question.questionD);
      return questionData;
    };

    const handleQuestions = async (
      questions: Array<Question>,
      examId: string,
      update = false
    ) => {
      for (let i = 0; i < questions.length; i++) {
        const question = questions[i];
        const questionData = processQuestionData(question, examId);
        try {
          update
            ? await updateQuestionById(question.questionId, questionData)
            : await addQuestion(questionData);

          // Update progress
          setProgress(Math.round(((i + 1) / totalQuestions) * 100));
        } catch (error) {
          console.error(
            `Failed to ${update ? 'update' : 'add'} question ${
              question.index
            }:`,
            error
          );
        }
      }
    };

    try {
      const examData = {
        ...examInput,
        questions: [],
        courseId,
        parentFolderId,
      };

      let newItemWithType;

      if (mode === 'edit' && examId) {
        const updatedItem = await updateExamById(examId, {
          ...examInput,
          questions: [],
        });
        newItemWithType = {
          ...updatedItem.data,
          type: 'exam',
          itemId: updatedItem.data.examId,
        };

        if (examInput.isEdittingQuestion) {
          await handleQuestions(examInput.questions, examId, true);
        }

        dispatch(updateCourseItem(newItemWithType));
      } else if (mode === 'create') {
        const newItem = await createNewExam(examData);
        newItemWithType = {
          ...newItem.data,
          type: 'exam',
          itemId: newItem.data.examId,
        };
        await handleQuestions(examInput.questions, newItem.data.examId);

        dispatch(addCourseItem(newItemWithType));
      }
    } catch (err) {
      console.error('Error saving exam:', err);
    } finally {
      setLoadingButton(false);
      closeDialog();
    }
  };

  const renderContent = () => {
    return (
      <>
        <div
          style={{
            display: activeTab === 0 ? 'block' : 'none',
            height: '100%',
          }}
        >
          <AnswerResultPage
            isDataAllFetched={isDataAllFetch}
            exam={exam || defaultExam}
            mode={mode}
            examInput={examInput}
            setExamInput={setExamInput}
            isTeacher={isTeacher}
            isNotOnDialog={isNotOnDialog}
            result={result}
            sortedResults={sortedResults}
          />
        </div>
        <div style={{ display: activeTab === 1 ? 'block' : 'none' }}>
          <LeaderBoardPage
            exam={exam}
            isTeacher={isTeacher}
            isNotOnDialog={isNotOnDialog}
            sortedResults={sortedResults}
          />
        </div>
      </>
    );
  };

  return (
    <Box
      sx={{
        maxWidth: '100%',
        pt: mode === 'view' ? 0 : 3,
        px: 2,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
      }}
    >
      {/* Stepper */}
      {mode === 'view' ? (
        <>
          {/* Content */}
          <Box
            sx={{
              my: 1,
              position: 'relative',
            }}
          >
            <Box display="flex" justifyContent="center">
              <Tabs
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
                centered
              >
                {fullSteps.map((step) => (
                  <Tab key={step.label} icon={step.icon} iconPosition="start" />
                ))}
              </Tabs>
            </Box>

            {renderContent()}
          </Box>
        </>
      ) : (
        <Box
          sx={{
            my: 1,
          }}
        >
          <AnswerResultPage
            isDataAllFetched={isDataAllFetch}
            exam={exam || defaultExam}
            mode={mode}
            examInput={examInput}
            setExamInput={setExamInput}
            isTeacher={isTeacher}
            isNotOnDialog={isNotOnDialog}
            result={null}
          />
        </Box>
      )}

      {isMobile && !isNotOnDialog && (
        // x button on top right
        <Button
          onClick={closeDialog}
          sx={{
            position: 'absolute',
            top: 10,
            right: 0,
            zIndex: 100,
          }}
        >
          <CloseIcon />
        </Button>
      )}

      {/* Save Button */}
      {!isNotOnDialog && (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            mt: isMobile ? 0 : 2,
          }}
        >
          {(mode === 'edit' || mode === 'create') && (
            <Button
              variant="contained"
              onClick={handleSave}
              disabled={loadingButton || isDataFilled}
            >
              {loadingButton ? (
                <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                  <CircularProgress
                    variant="determinate"
                    value={progress}
                    size={24}
                    color="inherit"
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: 'absolute',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="text.secondary"
                    >
                      {`${progress}%`}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                'Save'
              )}
            </Button>
          )}
          {!isMobile && (
            <Button
              variant="outlined"
              color="primary"
              onClick={closeDialog}
              sx={{ ml: 1 }}
            >
              Close
            </Button>
          )}
        </Box>
      )}
    </Box>
  );
};

export default ExamTabs;
