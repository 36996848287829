import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Typography,
  Box,
  IconButton,
  Stack,
  Drawer,
  Toolbar,
  useTheme,
  AppBar,
  Container,
  Dialog,
} from '@mui/material';
import { defaultResult, Exam, Question, Result, SectionType } from './type';
import QuestionComponent from './QuestionComponent';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { appActions, fetchTimeDiff } from '../../redux/slices/appSlice';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useResponsive from '../../hooks/useResponsive';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useParams } from 'react-router-dom';
import {
  addOneToExamTimes,
  createResult,
  fetchExamWithoutAnswerById,
  getExamTimes,
} from '../../api';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import CloseIcon from '@mui/icons-material/Close';
import useAuth from '../../hooks/useAuth';
import MainButton from '../Courses/MainButton';
import LoginDialog from './LoginDialog';
import CountDown from './CountDownTimer';
import CountDownToStart from './CountDownToStart';
import LoadingDisplay from '../Home/Loading';
import QuestionButtonList from './QuestionButtonList';
import NavigationBar from './NavigationBar';
import UpdateProfileDialog from '../../components/AppDialogs/UpdateProfileDialog';
import ErrorDisplay from '../Home/Error';
import ExamDetails from './ExamDetails';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import SwiperCore from 'swiper';
import TruncatedTextWithDialog from './TruncatedTextWithDialog';

const drawerWidth = 240;
const ExammingPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const isMobile = useResponsive('down', 'md');
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  const { examId } = useParams<{ examId: string }>();
  const [exam, setExam] = React.useState<Exam | null>(null);
  const [examTimes, setExamTimes] = React.useState<number>(0);
  const [result, setResult] = React.useState<Result>(defaultResult);
  const [formatSize, setFormatSize] = React.useState<number>(
    isMobile ? 0.8 : 1
  );
  const [openNav, setOpenNav] = React.useState<boolean>(() => false);
  const timediff = useAppSelector((state) => state.app.timediff);
  const isStandalone = window.matchMedia('(display-mode: standalone)').matches;
  const [showUpdateProfileLogout, setShowUpdateProfileLogout] = useState({
    show: false,
    check: false,
  });
  const countDownRef = useRef<{ getTimeLeft: () => number } | null>(null);
  const [questions, setQuestions] = React.useState<Question[]>([]);
  const [activeQuestion, setActiveQuestion] = React.useState<Question | null>(
    null
  );
  const [start, setStart] = React.useState(false);
  const [showUserForm, setShowUserForm] = useState(false);
  const [navigateFormContent, setNavigateFormContent] = useState({
    showNavigateForm: false,
    content: '',
    buttonContent: '',
    link: '',
  });
  const [showConfirmSubmitDialog, setShowConfirmSubmitDialog] = useState(false);
  const [showExamDetails, setShowExamDetails] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const swiperRef = useRef<SwiperCore>();

  useEffect(() => {
    setLoading(true);
    if (!exam) return;

    if (!exam.isExercise) {
      if (!exam.executedAt || timediff === null) return;
      const startAt = new Date(exam.executedAt);
      const now = new Date(Date.now() + timediff * 1000);
      const diff = startAt.getTime() - now.getTime();
      if (diff <= 0) {
        let countDown = exam.duration * 60 + Math.floor(diff / 1000);
        if (countDown < 0) {
          setNavigateFormContent({
            showNavigateForm: true,
            content: 'Bài kiểm tra đã kết thúc',
            buttonContent: 'Xem kết quả',
            link: '/answer/' + examId,
          });
          setLoading(false);
          return;
        }
      }
    }

    if (!user) {
      setShowUserForm(true);
      setLoading(false);
      return;
    } else {
      setShowUserForm(false);
    }

    if (exam.isExercise && user) setStart(true);

    setLoading(false);

    if (!user) return;
    if (
      exam.onlyForMembers &&
      user.buyCourses &&
      !user.buyCourses.some((course) => course.courseId === exam.courseId)
    ) {
      setNavigateFormContent({
        showNavigateForm: true,
        content: 'Bạn cần mua khóa học này để tham gia bài kiểm tra',
        buttonContent: 'Mua khóa học',
        link: '/course/' + exam.courseId,
      });
      return;
    }
    // if (!showUpdateProfileLogout.check) {
    //   setShowUpdateProfileLogout({
    //     show: true,
    //     check: true,
    //   });
    // }
  }, [
    exam,
    timediff,
    user,
    showUserForm,
    dispatch,
    examId,
    loading,
    // showUpdateProfileLogout,
  ]);

  useEffect(() => {
    if (!activeQuestion) return;
    if (swiperRef.current && activeQuestion !== undefined) {
      swiperRef.current.slideTo(questions.indexOf(activeQuestion));
    }
  }, [activeQuestion, questions]);

  // Use effect to execute the fetchTime function from the appSlice every 1 minute
  useEffect(() => {
    const interval = setInterval(() => {
      dispatch(fetchTimeDiff());
    }, 60000);

    return () => clearInterval(interval);
  }, [dispatch]);

  const handleToggleNavMenu = () => {
    //Set active question based on the swipe index
    isMobile &&
      setActiveQuestion(questions[swiperRef.current?.activeIndex || 0]);
    setOpenNav(!openNav);
  };

  //For later use
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!start) return;
      event.preventDefault();
      event.returnValue = ''; // This shows a confirmation dialog
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [start]);

  // Fetch exam data based on the examId
  useEffect(() => {
    const loadExam = async () => {
      try {
        const id = examId;
        if (!id) return;
        const res = await fetchExamWithoutAnswerById(id);
        const data = res.data;
        setExam(data);
        setExamTimes(data.examTimes);
      } catch (err) {
        console.error('Error fetching exam:', err);
        setError('Đề không tồn tại');
      }
    };

    loadExam();
  }, [examId]);

  useEffect(() => {
    if (!exam || !user || !user._id) return;

    if (exam.questions.length > 0) {
      setResult({
        ...result,
        studentId: user._id,
        examId: exam.examId,
        answers: exam.questions.map((question) => ({
          questionId: question.questionId,
          answer: '',
          check: false,
        })),
      });
    }
  }, [exam, user]);

  useEffect(() => {
    if (!exam) return;

    const addOne = async () => {
      // let check = false;

      // if (exam.isExercise) {
      //   check = true;
      // } else {
      //   if (!exam.executedAt || timediff === null) return;

      //   const startAt = new Date(exam.executedAt);
      //   const now = new Date(Date.now() + timediff * 1000);

      //   const diff = startAt.getTime() - now.getTime();
      //   if (diff <= 0) {
      //     let countDown = exam.duration * 60 + Math.floor(diff / 1000);
      //     if (countDown < 0) {
      //       check = false;
      //     } else {
      //       check = true;
      //     }
      //   } else {
      //     check = true;
      //   }
      // }

      // // Plus one of examTimes to the exam

      // if (
      //   check
      //   //&&
      //   // showUpdateProfileLogout.show === false &&
      //   // showUpdateProfileLogout.check
      // ) {
      //   await addOneToExamTimes(exam.examId);
      //   setExamTimes((prev) => prev + 1);
      // }

      await addOneToExamTimes(exam.examId);
      setExamTimes((prev) => prev + 1);
    };

    addOne();
  }, [exam]); //, showUpdateProfileLogout

  // Get examTimes every 3 seconds if exam is not exercise
  useEffect(() => {
    if (!exam || exam.isExercise) return;

    const interval = setInterval(async () => {
      const res = await getExamTimes(exam.examId);
      setExamTimes(res.data);
    }, 3000);

    return () => clearInterval(interval);
  }, [exam]);

  //Create new array of sections include title and questions (questions are grouped by same section)
  const sections = questions.reduce((acc, question) => {
    const sectionIndex = acc.findIndex(
      (section) => section.title === question.section
    );
    if (sectionIndex === -1) {
      acc.push({
        title: question.section,
        content: question.sectionContent,
        questions: [question],
      });
    } else {
      acc[sectionIndex].questions.push(question);
    }
    return acc;
  }, [] as SectionType[]);

  useEffect(() => {
    if (!exam) return;
    if (exam.questions.length > 0) {
      setQuestions(exam.questions);
      setActiveQuestion(exam.questions[0]);
    }
  }, [exam]);

  const showSingleQuestion = (question: Question) => {
    setActiveQuestion(question);
  };

  const handleSubmit = async (result: Result) => {
    if (countDownRef.current) {
      setLoadingSubmit(true);
      const timeLeft = countDownRef.current.getTimeLeft();
      result.completeTime = (exam?.duration || 0) * 60 - timeLeft;

      const maxRetries = 3; // Maximum number of retries
      let attempt = 0;
      let success = false;

      while (attempt < maxRetries && !success) {
        try {
          attempt++;
          // Attempt to create the result
          await createResult(result);

          // Simulate a 1-second delay to display the spinner
          await new Promise((resolve) => setTimeout(resolve, 1000));

          setNavigateFormContent({
            showNavigateForm: true,
            content: 'Chúc mừng bạn đã hoàn thành bài thi',
            buttonContent: 'Xem kết quả',
            link: '/answer/' + examId,
          });

          success = true; // Mark as successful if no error occurs
        } catch (error) {
          console.error(`Attempt ${attempt} failed:`, error);

          if (attempt === maxRetries) {
            // Notify user after maximum attempts
            appActions.showNotification({
              message: 'Nộp bài thất bại, vui lòng thử lại sau',
              severity: 'error',
            });
          }
        }
      }

      setLoadingSubmit(false);
    }
  };

  const desktopHeight = '70rem'; //38rem
  const mobileHeight = '90vh'; //93vh

  const CountDownComponent = useMemo(
    () => (
      <CountDown
        start={start}
        exam={exam}
        timediff={timediff || 0}
        onOutTime={() => {
          setNavigateFormContent({
            showNavigateForm: true,
            content: 'Bài kiểm tra đã kết thúc',
            buttonContent: 'Xem kết quả',
            link: '/answer/' + examId,
          });
        }}
        onTimeUp={() => {
          handleSubmit(result);
        }}
        ref={countDownRef}
      />
    ),
    [start, exam, timediff, examId, result]
  );

  const AppBarItems = (
    <Box
      sx={{
        flexGrow: 1,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {/* <Typography
        variant="h6"
        px={2}
        maxWidth={'30%'}
        sx={{
          display: '-webkit-box', // Required for limiting lines of text
          WebkitLineClamp: 1, // Limits to 2 lines; you can adjust this number
          WebkitBoxOrient: 'vertical', // Required for multiline ellipsis
          overflow: 'hidden', // Hides the overflowing text
          textOverflow: 'ellipsis', // Adds '...' at the end of the truncated text
          cursor: 'pointer',
        }}
        onClick={() => {
          setShowExamDetails(true);
        }}
      >
        {exam?.title}
      </Typography> */}
      <TruncatedTextWithDialog
        sx={{ maxWidth: isMobile ? '30%' : '100%', px: 2 }}
        content={exam?.title || ''}
        dialogContent={exam && <ExamDetails exam={exam} isNotOnDialog={true} />}
      />

      {isMobile && CountDownComponent}

      <Box
        sx={{
          borderRadius: 2,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          px: 2,
          py: 1,
          width: isMobile ? 'auto' : drawerWidth,
        }}
      >
        {!isMobile && CountDownComponent}

        <Box
          sx={{
            borderRadius: 2,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <PeopleOutlineIcon fontSize="small" />
          <Typography variant="subtitle2" ml={0.5}>
            {examTimes}
          </Typography>
        </Box>
      </Box>
      {isMobile && (
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          onClick={handleToggleNavMenu}
          sx={{
            color: (theme) => theme.palette.lighter.main,
            opacity: 0.6,
            '&:hover': {
              opacity: 1,
            },
          }}
        >
          <MenuIcon />
        </IconButton>
      )}
    </Box>
  );

  const renderQuestionList = () => {
    return (
      <Box
        sx={{
          backgroundColor: 'background.default',
          px: isMobile ? 3 : 1,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          pb: 1.5,
        }}
      >
        <QuestionButtonList
          sections={sections}
          isMobile={isMobile || false}
          activeQuestion={activeQuestion}
          showSingleQuestion={showSingleQuestion}
          result={result}
          check={(exam?.isExercise && result.examId !== '') || false}
        />

        <MainButton
          variant="first"
          text="Nộp bài"
          icon={<TextSnippetIcon />}
          isLoading={loadingSubmit}
          onClick={() => {
            setShowConfirmSubmitDialog(true);
          }}
          sx={{ width: '100%', borderRadius: 4 }}
        />
      </Box>
    );
  };

  if (error) {
    return <ErrorDisplay errorMessage={error} />;
  }

  if (loading) {
    return <LoadingDisplay loadingMessage="Đang tải đề..." />;
  }

  return (
    <Container maxWidth={'xl'} disableGutters sx={{ height: '100vh' }}>
      <AppBar
        position="static"
        elevation={0}
        sx={{
          backgroundColor: (theme) => theme.palette.background.default,
          color: (theme) => theme.palette.lighter.main,
          top: 0,
        }}
      >
        <Container
          maxWidth={false}
          sx={{
            paddingX: {
              xs: 1,
            },
          }}
          disableGutters
        >
          <Toolbar
            disableGutters
            sx={{
              minHeight: '50px !important',
            }}
          >
            {AppBarItems}
          </Toolbar>
        </Container>
      </AppBar>

      {/* Left Panel */}
      <Box
        display="flex"
        justifyContent="space-between"
        sx={{
          maxHeight: isMobile ? '100vh' : desktopHeight,
          height: 'calc(100% - 50px)',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexGrow: 1,
            padding: 1,
            px: 0,
            height: 'auto', //isMobile ? mobileHeight : desktopHeight,
            width: '100%',
            // marginRight: openNav && !isMobile ? `${drawerWidth}px` : '0px', // Change margin based on openNav state
            transition: 'margin-right 0.3s ease-in-out',
            '&::-webkit-scrollbar': {
              height: '8px', // Set the height of the horizontal scrollbar
            },
          }}
        >
          <Box width="100%" maxWidth="100%" position="relative" height="100%">
            {!start && (
              <Box
                mb={1}
                sx={{
                  px: 2,
                  height: 'calc(100% - 60px)',
                  maxWidth: '100%',
                }}
              >
                <CountDownToStart
                  exam={exam}
                  timediff={timediff || 0}
                  showUserForm={showUserForm}
                  // showUpdateProfile={showUpdateProfileLogout.show}
                  user={user}
                  onStart={setStart}
                />
              </Box>
            )}
            {activeQuestion && start && !isMobile && (
              <Box
                mb={1}
                sx={{
                  px: 2,
                  height: 'calc(100% - 60px)',
                  maxWidth: '100%',
                }}
              >
                <QuestionComponent
                  question={activeQuestion}
                  answer={
                    result.answers.find(
                      (answer) =>
                        answer.questionId === activeQuestion.questionId
                    )?.answer
                  }
                  editable={true}
                  sx={{
                    maxHeight: '100%',
                    overflow: 'auto',
                  }}
                  onAnswerChange={(answer) => {
                    const index = questions.indexOf(activeQuestion);
                    const newAnswers = [...result.answers];
                    newAnswers[index].answer = answer;
                    if (
                      (activeQuestion.type === 'mcq' && answer.length > 0) ||
                      (activeQuestion.type === 'mctf' &&
                        answer.length > 0 &&
                        !answer.includes('_'))
                    ) {
                      newAnswers[index].check = true;
                    }
                    setResult({ ...result, answers: newAnswers });
                  }}
                  formatSize={formatSize}
                  checkCorrect={
                    exam?.isExercise &&
                    result.answers.find(
                      (answer) =>
                        answer.questionId === activeQuestion.questionId
                    )?.check
                  }
                  onSAQuestionCheck={() => {
                    const index = questions.indexOf(activeQuestion);
                    const newAnswers = [...result.answers];
                    newAnswers[index].check = true;
                    setResult({ ...result, answers: newAnswers });
                  }}
                  showCheckButton={exam?.isExercise}
                />
              </Box>
            )}

            {activeQuestion && start && isMobile && (
              <Swiper
                onSwiper={(swiperInstance) => {
                  swiperRef.current = swiperInstance;
                }}
                style={{
                  height: 'calc(100% - 60px)',
                }}
              >
                {questions.map((question) => (
                  <SwiperSlide
                    key={question.questionId}
                    style={{
                      height: '100%', // Each slide takes the full height
                    }}
                  >
                    <Box
                      mb={1}
                      sx={{
                        px: 2,
                        height: '100%',
                      }}
                    >
                      <QuestionComponent
                        question={question}
                        answer={
                          result.answers.find(
                            (answer) =>
                              answer.questionId === question.questionId
                          )?.answer
                        }
                        editable={true}
                        sx={{
                          maxHeight: '100%',
                          overflow: 'auto',
                        }}
                        onAnswerChange={(answer) => {
                          const index = questions.indexOf(question);
                          const newAnswers = [...result.answers];
                          newAnswers[index].answer = answer;
                          if (
                            (question.type === 'mcq' && answer.length > 0) ||
                            (question.type === 'mctf' &&
                              answer.length > 0 &&
                              !answer.includes('_'))
                          ) {
                            newAnswers[index].check = true;
                          }
                          setResult({ ...result, answers: newAnswers });
                        }}
                        formatSize={formatSize}
                        checkCorrect={
                          exam?.isExercise &&
                          result.answers.find(
                            (answer) =>
                              answer.questionId === question.questionId
                          )?.check
                        }
                        onSAQuestionCheck={() => {
                          const index = questions.indexOf(question);
                          const newAnswers = [...result.answers];
                          newAnswers[index].check = true;
                          setResult({ ...result, answers: newAnswers });
                        }}
                        showCheckButton={exam?.isExercise}
                      />
                    </Box>
                  </SwiperSlide>
                ))}
              </Swiper>
            )}
            <NavigationBar
              activeQuestion={activeQuestion}
              questions={questions}
              setActiveQuestion={setActiveQuestion}
              handleToggleNavMenu={handleToggleNavMenu}
              setFormatSize={setFormatSize}
              loadingSubmit={loadingSubmit}
              setShowConfirmSubmitDialog={setShowConfirmSubmitDialog}
              showButton={true}
              //navigationString={navigationString}
            />
          </Box>
        </Box>

        {!isMobile && (
          <Box
            sx={{
              width: '20%',
              minWidth: drawerWidth,
              backgroundColor: theme.palette.background.default,
              borderColor: theme.palette.divider,
              maxHeight: isMobile ? '100vh' : desktopHeight,
              overflow: 'auto',
            }}
          >
            {renderQuestionList()}
          </Box>
        )}

        {isMobile && (
          <Drawer
            sx={{
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: '100%',
                boxSizing: 'border-box',
                backgroundColor: theme.palette.background.default,
                border: 'none',
                height: 'calc(100% - 48px)',
                top: 48,
              },
            }}
            variant="persistent"
            anchor="right"
            open={openNav}
            onClose={handleToggleNavMenu}
          >
            {renderQuestionList()}
            {isStandalone && (
              <Box sx={{ height: 14, backgroundColor: 'background.default' }} />
            )}
          </Drawer>
        )}
      </Box>
      {user && (
        <UpdateProfileDialog
          user={user}
          showUpdateProfileLogout={showUpdateProfileLogout.show}
          onClose={() => {
            setShowUpdateProfileLogout({
              show: false,
              check: true,
            });
          }}
        />
      )}
      <Dialog
        open={showUserForm}
        disableEscapeKeyDown
        aria-labelledby="user-dialog-title"
        maxWidth="sm"
        fullWidth
      >
        <LoginDialog
          isMobile={isMobile}
          onLogin={() =>
            setShowUpdateProfileLogout({
              show: true,
              check: false,
            })
          }
        />
      </Dialog>

      <Dialog open={navigateFormContent.showNavigateForm} onClose={() => {}}>
        <Stack alignItems="center" padding={2}>
          <Typography
            m={3}
            mx={3}
            variant="h6"
            component="div"
            sx={{ textAlign: 'center' }}
          >
            {navigateFormContent.content}
          </Typography>

          <MainButton
            variant="first"
            text={navigateFormContent.buttonContent}
            onClick={() => {
              navigate(navigateFormContent.link);
            }}
            sx={{ width: 160 }}
            isLoading={false}
          />
        </Stack>
      </Dialog>
      <Dialog
        open={showConfirmSubmitDialog}
        onClose={() => {
          setShowConfirmSubmitDialog(false);
        }}
      >
        <Stack alignItems="center" padding={3}>
          {/* x button on top right */}
          <IconButton
            aria-label="close"
            onClick={() => {
              setShowConfirmSubmitDialog(false);
            }}
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
            }}
          >
            <CloseIcon color="primary" />
          </IconButton>
          <Typography
            m={3}
            mx={3}
            variant="h6"
            component="div"
            sx={{ textAlign: 'center' }}
          >
            Bạn có chắc chắn muốn nộp bài?
          </Typography>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            width="100%"
          >
            <MainButton
              variant="first"
              text="Nộp bài"
              icon={<TextSnippetIcon />}
              isLoading={loadingSubmit}
              onClick={() => {
                handleSubmit(result);
              }}
              sx={{ borderRadius: 4, mx: 3 }}
            />
          </Box>
        </Stack>
      </Dialog>
      {/* <Dialog
        open={showExamDetails}
        onClose={() => {
          setShowExamDetails(false);
        }}
        maxWidth="sm"
      >
        <Box p={3}>
          {exam && <ExamDetails exam={exam} isNotOnDialog={true} />}
        </Box>
      </Dialog> */}
    </Container>
  );
};

export default ExammingPage;
