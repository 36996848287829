import React, { useState } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';

interface ImageBoxProps {
  src: string;
  alt: string;
  formatSize: number;
  sx?: SxProps<Theme>;
}

const ImageBox: React.FC<ImageBoxProps> = ({ src, alt, formatSize, sx }) => {
  const isMobile = useResponsive('down', 'md');
  const [imageSize, setImageSize] = useState({ width: 0, height: 0 });

  const handleImageLoad = (e: React.SyntheticEvent<HTMLImageElement>) => {
    const { naturalWidth, naturalHeight } = e.currentTarget;
    setImageSize({
      width: naturalWidth * 0.5, //* 0.5 //* 0.75
      height: naturalHeight * 0.5, //* 0.75
    });
  };

  return (
    <Box
      component="img"
      src={src}
      alt={alt}
      onLoad={handleImageLoad}
      sx={{
        float: 'right',
        marginLeft: 2,
        marginBottom: 2,
        width: imageSize.width * formatSize + 'px',
        height: 'auto',
        maxWidth: isMobile ? '100%' : '75%',
        ...sx,
      }}
    />
  );
};

export default ImageBox;
