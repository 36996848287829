import React, { useEffect, useState } from 'react';
import {
  useTheme,
  Container,
  Typography,
  Box,
  Stack,
  Divider,
  CircularProgress,
} from '@mui/material';
import { Exam, Result } from './type';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import useResponsive from '../../hooks/useResponsive';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { formatCompleteTime } from './extractQuestions';
import CountDownToResult from './CountDownToResult';

const drawerWidth = 240;

interface LeaderBoardPageProps {
  exam?: Exam | null;
  isTeacher?: boolean;
  isNotOnDialog?: boolean;
  sortedResults?: Result[];
}

const LeaderBoardPage: React.FC<LeaderBoardPageProps> = ({
  exam,
  isTeacher,
  isNotOnDialog,
  sortedResults,
}) => {
  const dispatch = useAppDispatch();
  const isMobile = useResponsive('down', 'md');
  const theme = useTheme();
  const navigate = useNavigate();
  const { user } = useAuth();
  // const { examId } = useParams<{ examId: string }>();

  const [loading, setLoading] = useState(true);
  const [showResult, setShowResult] = React.useState(false);
  const timediff = useAppSelector((state) => state.app.timediff);

  const desktopHeight = isNotOnDialog ? '70vh' : '70vh';
  const mobileHeight = isNotOnDialog ? '92vh' : '92vh';
  useEffect(() => {
    if (sortedResults !== undefined) {
      setLoading(false);
    }
  }, [sortedResults]);

  const StyledTypography = ({ children, ...props }: any) => (
    <Typography
      textAlign={'center'}
      fontWeight={600}
      {...props}
      sx={{
        background: `linear-gradient(to right, ${theme.palette.prize.first}, #ff7e5f)`,
        WebkitBackgroundClip: 'text',
        WebkitTextFillColor: 'transparent',
        ...props.sx,
      }}
    >
      {children}
    </Typography>
  );

  return (
    <Container
      maxWidth={'xl'}
      disableGutters
      sx={{
        minHeight: isMobile ? mobileHeight : desktopHeight,
      }}
    >
      {!showResult && (
        <CountDownToResult
          exam={exam || null}
          timediff={timediff || 0}
          isTeacher={isTeacher}
          onShowResult={setShowResult}
        />
      )}
      <StyledTypography variant="h3" sx={{ mt: 2, mb: 2, fontWeight: 'bold' }}>
        {exam?.title}
      </StyledTypography>
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        sx={{
          overflow: 'auto',
          '&::-webkit-scrollbar': {
            height: '8px', // Set the height of the horizontal scrollbar
          },
        }}
        px={isMobile ? 1 : 2}
      >
        {/* Top header columns boxes */}
        <Box width="100%">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            px={1}
            py={1}
            mt={1}
          >
            <StyledTypography
              sx={{ maxWidth: '5%', width: '5%', minWidth: 20 }}
            >
              #
            </StyledTypography>
            <Stack
              direction="row"
              spacing={1}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{
                maxWidth: '40%',
                width: '40%',
                minWidth: 240,
              }}
            >
              <StyledTypography>Tên</StyledTypography>
            </Stack>

            <StyledTypography
              sx={{
                maxWidth: '10%',
                width: '10%',
                minWidth: 80,
              }}
            >
              Điểm
            </StyledTypography>
            <StyledTypography
              sx={{ maxWidth: '10%', width: '10%', minWidth: 100 }}
            >
              Thời gian
            </StyledTypography>
            <StyledTypography
              sx={{ maxWidth: '20%', width: '20%', minWidth: 200 }}
            >
              Trường
            </StyledTypography>
            <StyledTypography
              sx={{ maxWidth: '25%', width: '25%', minWidth: 300 }}
            >
              Email
            </StyledTypography>
          </Box>

          <Divider
            sx={{
              backgroundColor: 'text.secondary',
              opacity: 1,
              minWidth: 940,
            }}
          />
        </Box>

        {/* Show results as a list of box */}
        {sortedResults?.map((result, index) => (
          <Box width="100%" key={result.studentId}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
                padding: 1,
                px: 1,
                py: 1.5,
              }}
            >
              <Typography
                textAlign="center"
                fontWeight={500}
                sx={{ maxWidth: '5%', width: '5%', minWidth: 20 }}
              >
                {index + 1}
              </Typography>
              <Stack
                direction="row"
                spacing={1}
                alignItems={'center'}
                justifyContent={'center'}
                sx={{
                  maxWidth: '40%',
                  width: '40%',
                  minWidth: 240,
                }}
              >
                <img
                  src={result.student?.avatar}
                  alt="avatar"
                  style={{ height: 30, width: 30, borderRadius: '50%' }}
                  referrerPolicy="no-referrer"
                />

                <Typography fontWeight={500}>{result.student?.name}</Typography>
              </Stack>

              <Typography
                textAlign="center"
                fontWeight={500}
                sx={{
                  maxWidth: '10%',
                  width: '10%',
                  minWidth: 80,
                }}
              >
                {isTeacher || showResult ? (
                  result.score
                ) : (
                  <CircularProgress size="1rem" disableShrink />
                )}
              </Typography>

              <Typography
                textAlign="center"
                fontWeight={500}
                sx={{ maxWidth: '10%', width: '10%', minWidth: 100 }}
              >
                {formatCompleteTime(result.completeTime)}
              </Typography>

              <Typography
                textAlign="center"
                fontWeight={500}
                sx={{ maxWidth: '20%', width: '20%', minWidth: 200 }}
              >
                {result.student?.school}
              </Typography>
              <Typography
                textAlign="center"
                fontWeight={500}
                sx={{ maxWidth: '25%', width: '25%', minWidth: 300 }}
              >
                {result.student?.email}
              </Typography>
            </Box>
            <Divider
              sx={{
                backgroundColor: 'text.secondary',
                opacity: 0.3,
                minWidth: 940,
              }}
            />
          </Box>
        ))}
      </Box>
    </Container>
  );
};

export default LeaderBoardPage;
