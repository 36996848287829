import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CourseProps } from '../../pages/Home/Course';
import { Result } from '../../pages/Exams/type';

export interface CourseItem {
  _id: string; // MongoDB ID for linkexam
  itemId: string;
  parentFolderId?: string;
  type: 'exam' | 'video' | 'pdf' | 'subfolder' | 'linkexam';
  title: string;
  url: string;
  createdAt: string;
  examTimes?: number;
  views?: number;
  items?: CourseItem[];
  onlyForMembers?: boolean;
  isExercise?: boolean;
  subject?: string;
  duration?: number;
  executedAt?: Date;
  result?: Result;
}

// export interface CourseProps {
//   courseId: string;
//   image: string;
//   title: string;
//   description: string;
//   favorites: number;
//   members: number;
//   exams: number;
//   videos: number;
//   pdfs: number;
//   learningObjectives: string[];
//   items: CourseItem[];
//   lastUpdatedAt: string;
//   createdBy: {
//     _id: string;
//     name: string;
//   };
//   contactLink?: string;
//   links?: string[];
//   price?: number;
//   rates: number[];
// }

interface CourseState {
  course: CourseProps;
  loading: boolean;
  error: string | null;
}

const initialState: CourseState = {
  course: {
    courseId: '',
    image: '',
    title: '',
    description: '',
    favorites: 0,
    members: 0,
    exams: 0,
    videos: 0,
    pdfs: 0,
    learningObjectives: [],
    items: [],
    lastUpdatedAt: '2024-06-25',
    createdBy: {
      _id: '',
      name: '',
      avatar: '',
    },
    contactLink: '',
    links: [],
    price: 0,
    rates: [],
    createdAt: '2024-06-25',
    subject: '',
  },
  loading: false,
  error: null,
};

function addCourseItemRecursive(
  items: CourseItem[],
  parentFolderId: string,
  newItem: CourseItem
): boolean {
  for (const item of items) {
    if (item.itemId === parentFolderId && item.type === 'subfolder') {
      item.items = item.items || [];
      item.items.push(newItem);
      return true;
    }
    if (
      item.items &&
      addCourseItemRecursive(item.items, parentFolderId, newItem)
    ) {
      return true;
    }
  }
  return false;
}

function deleteCourseItemRecursive(
  items: CourseItem[] | undefined,
  itemToDelete: CourseItem
): boolean {
  if (!items) {
    return false;
  }

  for (let i = 0; i < items.length; i++) {
    if (
      items[i].itemId === itemToDelete.itemId &&
      items[i].type === itemToDelete.type
    ) {
      items.splice(i, 1);
      return true;
    }

    if (
      items[i].items && // Ensure items[i].items is defined
      deleteCourseItemRecursive(items[i].items, itemToDelete)
    ) {
      return true;
    }
  }
  return false;
}

function updateCourseItemRecursive(
  items: CourseItem[] | undefined,
  updatedItem: CourseItem
): boolean {
  if (!items) {
    return false;
  }
  for (let i = 0; i < items.length; i++) {
    if (
      items[i].itemId === updatedItem.itemId &&
      items[i].type === updatedItem.type
    ) {
      items[i] = updatedItem;
      return true;
    }
    if (
      items[i].items &&
      updateCourseItemRecursive(items[i].items, updatedItem)
    ) {
      return true;
    }
  }
  return false;
}

const courseSlice = createSlice({
  name: 'course',
  initialState,
  reducers: {
    setCourseItems(state, action: PayloadAction<CourseProps>) {
      state.course = action.payload;
      state.loading = false;
      state.error = null;
    },
    addCourseItem(state: CourseState, action: PayloadAction<CourseItem>) {
      const { parentFolderId } = action.payload;
      if (parentFolderId) {
        if (parentFolderId === state.course.courseId) {
          state.course.items.push(action.payload);
        } else {
          addCourseItemRecursive(
            state.course.items,
            parentFolderId,
            action.payload
          );
        }
      }
      state.course.lastUpdatedAt = new Date().toISOString();
      //Sort items by createdAt
      state.course.items.sort((a, b) => {
        return (
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        );
      });
      //Put all subfolders at the end, keep the order of other items
      const subfolders = state.course.items.filter(
        (item) => item.type === 'subfolder'
      );
      const otherItems = state.course.items.filter(
        (item) => item.type !== 'subfolder'
      );
      state.course.items = otherItems.concat(subfolders);
    },
    updateCourseItem(state, action: PayloadAction<CourseItem>) {
      if (state.course) {
        const index = state.course.items.findIndex(
          (item) =>
            item.itemId === action.payload.itemId &&
            item.type === action.payload.type
        );
        if (index !== -1) {
          state.course.items[index] = action.payload;
        }
      }
      updateCourseItemRecursive(state.course.items, action.payload);
    },
    deleteCourseItem(state, action: PayloadAction<any>) {
      if (state.course) {
        state.course.items = state.course.items.filter(
          (item) =>
            !(
              item.itemId === action.payload.itemId &&
              item.type === action.payload.type
            )
        );
      }
      deleteCourseItemRecursive(state.course.items, action.payload);
    },
    // Assign result to the item, input is an array of results, check if the item is an exam and has itemId = result.examId
    assignResultToItem(state, action: PayloadAction<Result[]>) {
      for (const result of action.payload) {
        for (const item of state.course.items) {
          if (
            item.type === 'exam' &&
            item.itemId === result.examId &&
            !item.result
          ) {
            item.result = result;
          } else if (item.items) {
            for (const subItem of item.items) {
              if (
                subItem.type === 'exam' &&
                subItem.itemId === result.examId &&
                !subItem.result
              ) {
                subItem.result = result;
              }
            }
          }
        }
      }
    },

    updateItemParentFolder(state, action: PayloadAction<any>) {
      // if (state.course) {
      //   const index = state.course.items.findIndex(
      //     (item) =>
      //       item.itemId === action.payload.itemId &&
      //       item.type === action.payload.type
      //   );
      //   if (index !== -1) {
      //     state.course.items[index].parentFolderId =
      //       action.payload.parentFolderId;
      //   }
      // }
    },
    addCourseToFavorite(state) {
      if (state.course) {
        state.course.favorites += 1;
      }
    },
    removeCourseFromFavorite(state) {
      if (state.course) {
        state.course.favorites = Math.max(0, state.course.favorites - 1);
      }
    },
    beCourseMember(state) {
      if (state.course) {
        state.course.members += 1;
      }
    },
    unBeCourseMember(state) {
      if (state.course) {
        state.course.members = Math.max(0, state.course.members - 1);
      }
    },
    rateCourse(state, action: PayloadAction<number>) {
      if (state.course) {
        state.course.rates.push(action.payload);
      }
    },
    unRateCourse(state, action: PayloadAction<number>) {
      // Remove the first occurrence of the rating
      if (state.course) {
        const index = state.course.rates.indexOf(action.payload);
        if (index !== -1) {
          state.course.rates.splice(index, 1);
        }
      }
    },
    setLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload;
    },
    setError(state, action: PayloadAction<string | null>) {
      state.error = action.payload;
    },
  },
});

export const {
  setCourseItems,
  addCourseItem,
  updateCourseItem,
  deleteCourseItem,
  assignResultToItem,
  updateItemParentFolder,
  addCourseToFavorite,
  removeCourseFromFavorite,
  beCourseMember,
  unBeCourseMember,
  rateCourse,
  unRateCourse,
  setLoading,
  setError,
} = courseSlice.actions;

export default courseSlice.reducer;
